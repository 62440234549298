<template>
    <custom-modal
        title="系统提示"
        :width="560"
        :visible="visible"
        @close="handleCancel">
        <div v-show="isAccept">
            确认接受当前任务吗，接受后当前任务会进入您的待办任务，由您来跟进处理！
        </div>
        <div v-show="isReject">
            确认拒绝当前任务吗，拒绝后将会由原任务经办人跟进处理！
        </div>
        <template slot="customFooter">
            <a-button key="reset" @click="handleCancel">
                取消
            </a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                提交
            </a-button>
        </template>
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import auditTaskForwardService from '@service/task/auditTaskForwardService'
import { ERROR_FORWARD_SUCCESS } from '@constant/error'

export default {
    name: 'AcceptOrRejectModal',
    components: {
        AButton,
        CustomModal
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
    /**
     * 点击接受任务
     * */
        isAccept() {
            return this.type === 'accept'
        },
        /**
     * 点击拒绝任务
     * */
        isReject() {
            return this.type === 'reject'
        }

    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        handleOk() {
            this.submitService()
        },
        async submitService() {
            const { isAccept, info } = this
            const { riskControlNumber } = info
            try {
                await auditTaskForwardService({
                    audit: isAccept,
                    riskControlNumber
                })
                this.$message.success(ERROR_FORWARD_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
