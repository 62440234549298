<!-- 风控信息 -->
<template>
    <descriptions-with-head class="fill-ro-info" :title="record.name">
        <div
            v-show="!showFinancial"
            slot="button"
            style="display: flex; align-items: center">
            <a-button
                style="margin-right: 10px"
                type="primary"
                @click="handleLookBasic">
                查看基本信息
            </a-button>
            <img
                width="30px"
                height="30px"
                src="~@assets/images/page.png"
                @click="handleSameScreen">
        </div>
        <div>
            <div :class="{showScreen:record.showScreen}">
                <!-- 点击同屏显示 附件类型组件 -->
                <attachment-panel
                    v-show="record.showScreen" />
                <form-create
                    v-for="(item,index) in dataList"
                    v-show="index === currentStep"
                    v-model="value"
                    :key="item.id"
                    :ref="`formCreateRef${index}`"
                    :rule="item.rule"
                    class="fill-ro-info__form-create"
                    :class="{formChange:record.showScreen}"
                />
                <!-- 财务报表列表 -->
                <financial-list-panel
                    v-if="showFinancial"
                    :show-copy="true"
                    :current-step="currentStep"
                    :financial-list="financialList" />
            </div>
            <credit-report
                v-if="showReport"
                ref="reportRef"
                :info="reportInfo"
                :is-look="false" />
        </div>

        <drag-resize
            :visible="visible"
            :init-height="350"
            @close="visible=false">
            <basic-info :info="riskOrderDetailDTO" />
        </drag-resize>
    </descriptions-with-head>
</template>

<script>
import { Button } from 'ant-design-vue'
import FormCreate from '@weights/FormCreate'
import AttachmentPanel from '../AttachmentPanel'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import FinancialListPanel from '@weights/FinancialListPanel'
import getTaskInfoService from '@service/task/getTaskInfoService'

export default {
    name: 'FillProjectInfo',
    components: {
        AButton: Button,
        FinancialListPanel,
        AttachmentPanel,
        DescriptionsWithHead,
        FormCreate,
        CreditReport: () => import('@weights/CreditReport'),
        DragResize: () => import('@weights/DragResize'),
        BasicInfo: () => import('@weights/TaskAndOrderCom/BasicInfo')
    },
    props: {
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        currentStep: {
            type: Number,
            default: 0
        },
        dataList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            visible: false,
            value: {},
            riskOrderDetailDTO: {},
            financialList: []// 财报数据
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        showFinancial() {
            const { record } = this
            return record.showFinancial
        },
        showReport() {
            return this.currentStepIsShowReport(this.record)
        },
        reportInfo() {
            return this.record.enterpriseCreditReport
        }
    },
    created() {
        this.getTaskInfo()
    },
    methods: {
        async getTaskInfo() {
            const { riskControlNumber } = this
            const { riskOrderDetailDTO } = await getTaskInfoService({
                riskControlNumber
            })
            this.riskOrderDetailDTO = riskOrderDetailDTO
        },
        handleLookBasic() {
            this.visible = true
        },
        /**
         * 当前步骤是否提供征信报告
         * */
        currentStepIsShowReport(record) {
            const { showReport, isSelectReport } = record
            return showReport && isSelectReport
        },
        handleNext() {
            const { dataList } = this
            for (let i = 0; i < dataList.length; i++) {
                const dataItem = dataList[i]
                // formCreate校验
                const res = this.$refs[`formCreateRef${i}`][0].submit()
                if (!res) {
                    return {
                        code: 0,
                        index: i
                    }
                }
                // 征信报告检验(取每一步骤的)
                if (this.currentStepIsShowReport(dataItem)) {
                    const reportRes = this.$refs.reportRef.submit()
                    if (!reportRes) {
                        return {
                            code: 0,
                            index: i
                        }
                    }
                    this.$emit('report', reportRes, i)
                } else {
                    this.$emit('report', null, i)
                }
            }
            return {
                code: 1
            }
        },
        handleSave() {
            const { record } = this
            if (this.currentStepIsShowReport(record)) {
                this.$emit('report', this.$refs.reportRef.form, this.currentStep)
            }
        },
        /**
         * 左右同屏
         * */
        handleSameScreen() {
            const isShow = this.record.showScreen || false
            this.$set(this.record, 'showScreen', !isShow)
        }
    }
}
</script>

<style lang='scss'>
.fill-ro-info {
    .fill-ro-info__model-item {
        display: inline-block;
        width: 50%
    }

    .changeItem {
        width: 50% !important;
    }

    .showScreen {
        display: flex;
        flex-shrink: 0;
    }

    .formChange {
        width: 50%;

        .ant-col-12 {
            width: 100% !important;
        }
    }
}

</style>
