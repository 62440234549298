<template>
    <search-panel :model="form" layout="vertical" @search="handleSearch" @refresh="handleRefresh">
        <div slot="title">筛选查询</div>
        <form-col  v-bind="formLayout" label="风控单号" prop="riskControlNumber">
            <template slot="formElements">
                <a-input v-model="form.riskControlNumber" placeholder="请输入风控单号" />
            </template>
        </form-col>
        <!--    <form-col  v-bind="formLayout" label="保险机构" prop="insuranceAgencyName"> -->
        <!--      <template slot="formElements"> -->
        <!--        <a-input v-model="form.insuranceAgencyName" placeholder="请输入保险机构"></a-input> -->
        <!--      </template> -->
        <!--    </form-col> -->
        <form-col  v-bind="formLayout" label="产品分类" prop="riskCategoryId">
            <product-category-cascader v-model="form.riskCategoryId"
                                       slot="formElements" />
        </form-col>
        <form-col  v-bind="formLayout" label="项目名称" prop="projectName">
            <template slot="formElements">
                <a-input v-model="form.projectName" placeholder="请输入项目名称" />
            </template>
        </form-col>
        <form-col  v-bind="formLayout" label="负责人" prop="assigneeName">
            <a-input
                v-model="form.assigneeName"
                slot="formElements"
                placeholder="请输入负责人" />
        </form-col>
    </search-panel>
</template>

<script>
import { Input as AInput } from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

import { filterParams } from '@utils/search.js'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'

export default {
    name: 'TDSearchPanel',
    components: {
        AInput,
        SearchPanel,
        FormCol,
        ProductCategoryCascader
    },
    data() {
        return {
            form: {
                riskControlNumber: '',
                insuranceAgencyName: '',
                riskCategoryId: '',
                projectName: '',
                assigneeName: ''
            },
            formLayout: {
                span: 5,
                labelSpan: 5,
                wrapperSpan: 18
            }
        }
    },
    methods: {
        handleSearch() {
            this.$emit('search', filterParams(this.form))
        },
        handleRefresh() {
            this.$emit('refresh', filterParams(this.form))
        }
    }
}
</script>

<style lang="scss">
</style>
