<!-- 添加财报信息父组件 -->
<template>
    <page-layout :show-loading="true" :loading="loading">
        <head-form-panel
            ref="headForm"
            :finance-data="financeData"
            :is-show-look="isShowLook"
            @change="handleChange" />
        <container-panel class="add-financial-report_container">
            <template slot="content">
                <!-- 步骤组件 -->
                <fill-finance-info
                    v-if="financeData"
                    ref="fillRef"
                    :change-fill-date="fillDate"
                    :is-show-look="isShowLook"
                    :show-red="showRed"
                    :finance-data="financeData"
                    :current-step="currentStep" />
                <a-form-model-item
                    :wrapper-col="{ span: 12, offset: 6 }"
                    style="margin-top: 30px; text-align: center">
                    <custom-button
                        v-show="showCancelButton"
                        text="取消"
                        @click="handleCancel" />
                    <custom-button
                        v-show="!showCancelButton"
                        text="上一步"
                        type="default"
                        @click="handlePrev" />
                    <custom-button
                        v-show="!isShowLook"
                        :loading="saveLoading"
                        text="保存"
                        type="primary"
                        @click="handleSave(true)" />
                    <a-button v-show="!showSubmitButton" type="primary" @click="handleNext">
                        下一步
                    </a-button>
                    <a-button type="primary" @click="handleCalculate">
                        自动计算
                    </a-button>
                    <a-button
                        v-show="showSubmitButton && !isShowLook"
                        type="primary"
                        :loading="submitLoading"
                        @click="handleSubmit">
                        提交
                    </a-button>
                </a-form-model-item>
            </template>
        </container-panel>
        <a-back-top />
    </page-layout>
</template>

<script>
import {
    FormModel as AFormModel,
    Button as AButton, BackTop as ABackTop
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import ContainerPanel from '@components/ContainerPanel'

import getFinancialReportService from '@service/task/getFinancialReportService'
import getFinancialReportInfoService from '@service/task/getFinancialReportInfoService'
import addFinancialReportService from '@service/task/addFinancialReportService'
import editFinancialReportService from '@service/task/editFinancialReportService'
import saveFinancialReportService from '@service/task/saveFinancialReportService'

import HeadFormPanel from './HeadFormPanel'
import CustomButton from '@components/Button'
import { ERROR_COMMIT_EDIT, ERROR_COMMIT_SUCCESS } from '@constant/error'

export default {
    name: 'AddFinancialReport',
    components: {
        PageLayout,
        HeadFormPanel,
        AFormModelItem: AFormModel.Item,
        AButton,
        CustomButton,
        ContainerPanel,
        ABackTop,
        FillFinanceInfo: () => import('./FillFinanceInfo')// 表组件
    },
    data() {
        return {
            taskInfo: {},
            riskOrderDetailDTO: {}, // 项目基本信息
            associatedOrderList: [], // 项目关联订单
            stepLength: 3,
            loading: false, // 页面loading
            submitLoading: false, // 提交按钮loading
            saveLoading: false, // 保存按钮loading
            currentStep: 0, // 当前第几步
            financeData: null, // 财报表数据
            fillDate: ''
        }
    },
    computed: {
        showCancelButton() {
            const { currentStep } = this
            return currentStep === 0
        },
        showSubmitButton() {
            const { stepLength, currentStep } = this
            return currentStep === stepLength - 1
        },
        /**
         * 日期 查看和修改接口参数
         * */
        reportDate() {
            return this.$route.query.reportDate
        },
        /**
         * id 区分修改和查看
         * */
        id() {
            return this.$route.query.id
        },
        /**
         * isShowLook 是否是查看
         * id是修改
         * */
        isShowLook() {
            const { reportDate, id } = this
            return reportDate && !id
        },
        /**
         * 订单号 接口参数
         * */
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        serviceFn() {
            const { id } = this
            return id ? editFinancialReportService : addFinancialReportService
        },
        commitMsg() {
            const { id } = this
            return id ? ERROR_COMMIT_EDIT : ERROR_COMMIT_SUCCESS
        },
        // 添加时是否需要标红
        showRed() {
            const { financeData } = this
            return Boolean(financeData.lastYearDataExist)
        }
    },
    created() {
        const { reportDate } = this
        // 查看或者修改
        if (reportDate) {
            this.getInitData({
                reportDate
            })
        } else { // 添加
            this.getData()
        }
    },
    methods: {
        handleChange(res, reportDate) {
            this.fillDate = res
            // 日期回显财报表
            if (!reportDate.includes('undefined')) {
                this.getData({
                    reportDate
                })
            }
        },
        handleCancel() {
            this.$router.back()
        },
        /**
         * 保存
         */
        handleSave(showMessage) {
            this.validateFn().then(async res => {
                this.handleCalculate()
                const { financeData } = this
                const params = {
                    ...financeData,
                    ...res
                }
                try {
                    this.saveLoading = true
                    await saveFinancialReportService(params)
                    showMessage && this.$message.success(ERROR_COMMIT_SUCCESS)
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e.message)
                } finally {
                    this.saveLoading = false
                }
            })
        },
        /**
         * 上一步
         */
        handlePrev() {
            this.currentStep -= 1
        },
        /**
         * 下一步
         */
        handleNext() {
            this.validateFn().then(async () => {
                await this.handleSave()
                this.currentStep += 1
            })
        },
        /**
         * 财报自动计算
         */
        handleCalculate() {
            this.$refs.fillRef.handleCalculate()
        },
        validateFn() {
            return new Promise((resolve, reject) => {
                // 头部内容校验
                const res = this.$refs.headForm.handleFormValidate()
                if (!res) {
                    this.$message.warning('请填写财报信息')
                    reject()
                    return
                }
                // 表格内容校验
                const vRes = this.$refs.fillRef.validate()
                if (!vRes) {
                    this.$message.warning('数据填写有误')
                    reject()
                    return
                }
                resolve(res)
            })
        },
        /**
         * 提交
         */
        handleSubmit() {
            this.validateFn().then(async res => {
                this.handleCalculate()
                const { commitMsg, serviceFn, financeData } = this
                const params = {
                    ...financeData,
                    ...res
                }
                try {
                    this.submitLoading = true
                    await serviceFn(params)
                    this.$message.success(commitMsg)
                    this.$router.back()
                } catch (e) {
                    this.$message.error(e.message)
                    this.$captureException(e.message)
                } finally {
                    this.submitLoading = false
                }
            })

        },
        /**
         * 获取财报数据（添加）
         * */
        async getData(obj = {}) {
            try {
                this.loading = true
                const { riskControlNumber } = this
                this.financeData = await getFinancialReportService({
                    riskControlNumber,
                    ...obj
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        /**
         * 获取财报数据 回显（修改）
         * */
        async getInitData(params) {
            const { riskControlNumber } = this
            try {
                this.loading = true
                this.financeData = await getFinancialReportInfoService({
                    ...params,
                    riskControlNumber
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }

    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.add-financial-report_container {
    min-width: $min-width;
    .ant-btn {
        min-width: $custom-button-width;
        margin-right: $custom-button-margin-right;
    }
}
</style>
