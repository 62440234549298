<!-- 填写财务报表格 -->
<template>
    <div class="fill-finance-info">
        <a-descriptions :title="dataObj[currentStep].title">
            <a-descriptions-item label="企业名称">
                {{ financeData.enterpriseName }}
            </a-descriptions-item>
            <a-descriptions-item label="填表日期">
                {{ fillDate }}
            </a-descriptions-item>
            <a-descriptions-item label="单位">元</a-descriptions-item>
        </a-descriptions>
        <a-button
            v-if="!isShowLook"
            type="primary"
            @click="handleFillZero">
            一键填0
        </a-button>
        <a-form-model
            ref="ruleForm"
            :model="form"
            class="fill-finance-info__container"
            :class="{dynamicTable:currentStep!==0}">
            <table>
                <thead class="fill-finance-info__thead">
                    <tr>
                        <th
                            v-for="item in dataObj[currentStep].ths"
                            :key="item">
                            {{ item }}
                        </th>
                    </tr>
                </thead>
                <tbody v-if="form.dataList.length">
                    <tr
                        v-for="(item,index) in form.dataList"
                        :key="item.id">
                        <template v-if="item">
                            <td>
                                <a-form-model-item>
                                    {{ item.name }}
                                </a-form-model-item>
                            </td>
                            <td>
                                <a-form-model-item
                                    :prop="`dataList[${index}][${leftModel}]`"
                                    :rules="attributeRules">
                                    <a-input
                                        v-if="showInput(item)"
                                        v-model="item[leftModel]"
                                        :disabled="isDisabled(item)"
                                        @keyup="handleInputKeyup($event,'dataList',index,leftModel)" />
                                </a-form-model-item>
                            </td>
                            <td>
                                <a-form-model-item
                                    :prop="`dataList[${index}][${rightModel}]`"
                                    :rules="attributeRules">
                                    <a-input
                                        v-if="showInput(item)"
                                        v-model="item[rightModel]"
                                        :class="{red:item.showRed}"
                                        :disabled="isDisabled(item)"
                                        @keyup="handleInputKeyup($event,'dataList',index,rightModel)"
                                        @change="changeRightInput($event,item)" />
                                </a-form-model-item>
                            </td>
                        </template>
                        <template v-else>
                            <td>
                                <div />
                            </td>
                            <td>
                                <div />
                            </td>
                            <td>
                                <div />
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>

            <table
                v-show="currentStep===0">
                <!--          class="fill-finance-info__container__table" -->
                <thead class="fill-finance-info__thead">
                    <tr>
                        <th
                            v-for="item in dataObj[currentStep].ths2"
                            :key="item">
                            {{ item }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item,index) in form.financeRightArr"
                        :key="item.id">
                        <td>
                            <a-form-model-item>
                                {{ item.name }}
                            </a-form-model-item>
                        </td>
                        <td>
                            <a-form-model-item
                                :prop="`financeRightArr[${index}][${leftModel}]`"
                                :rules="attributeRules">
                                <a-input
                                    v-if="showInput(item)"
                                    v-model="item[leftModel]"
                                    :disabled="isDisabled(item)"
                                    @keyup="handleInputKeyup($event,'financeRightArr',index,leftModel)" />
                            </a-form-model-item>
                        </td>
                        <td>
                            <a-form-model-item
                                :prop="`financeRightArr[${index}][${rightModel}]`"
                                :rules="attributeRules">
                                <a-input
                                    v-if="showInput(item)"
                                    v-model="item[rightModel]"
                                    :class="{red:item.showRed}"
                                    :disabled="isDisabled(item)"
                                    @keyup="handleInputKeyup($event,'financeRightArr',index,rightModel)"
                                    @change="changeRightInput($event,item)" />
                            </a-form-model-item>
                        </td>
                    </tr>
                </tbody>
            </table>
        </a-form-model>
    </div>
</template>

<script>
// const {financeAddBOList, cashValueAddBOList, incomeValueAddBOList} = financeData
// financeAddBOList 资产负债表
// cashValueAddBOList 现金流量表
// incomeValueAddBOList 利润表
import {
    Descriptions as ADescriptions,
    Input as AInput,
    Button as AButton,
    FormModel as AFormModel
} from 'ant-design-vue'
import { FINANCIAL_INIT, FINANCIAL_OBJECT, FINANCIAL_SUM } from '@constant/enum'
import { validateNumber } from '@constant/validates'

const dataObj = {
    0: {
        title: '资产负债表',
        key: 'financeAddBOList',
        ths: ['资产', '期末余额', '年初余额'],
        ths2: ['负债和所有者权益(或股东权益)', '期末余额', '年初余额']
    },
    1: {
        title: '利润表',
        key: 'incomeValueAddBOList',
        ths: ['项目', '本期金额', '上期金额']
    },
    2: {
        title: '现金流量表',
        key: 'cashValueAddBOList',
        ths: ['项目', '本期金额', '上期金额']
    }
}
export default {
    name: 'FillFinanceInfo',
    components: {
        AInput,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        AButton,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    props: {
        // 查看
        isShowLook: {
            type: Boolean,
            default: false
        },
        // 上年末对应本年初
        showRed: {
            type: Boolean,
            default: false
        },
        currentStep: {
            type: Number,
            default: 0
        },
        financeData: {
            type: Object,
            default() {
                return {}
            }
        },
        /**
         * 修改填表日期
         * */
        changeFillDate: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            FINANCIAL_INIT,
            FINANCIAL_OBJECT,
            FINANCIAL_SUM,
            dataObj,
            financeLeftArr: [], // 资产负债表左边数据
            financeRightArr: [], // 资产负债表右边数据
            attributeRules: [
                {
                    validator: validateNumber
                }
            ],
            form: {},
            cacheInput: {}, // 该缓存记录所有财报的输入框信息
            cacheCalculate: {}// 该缓存记录所有财报的需要自动计算总和信息
        }
    },
    computed: {
        // 左侧input的v-model绑定字段(期末)
        leftModel() {
            const { currentStep } = this
            return currentStep === 0 ? 'closingingBalance' : 'currentPeriod'
        },
        // 右侧input的v-model绑定字段(期初)
        rightModel() {
            const { currentStep } = this
            return currentStep === 0 ? 'openingBalance' : 'priorPeriod'
        },
        // 日期文字显示
        fillDate() {
            const { financeData, changeFillDate } = this
            if (changeFillDate) return changeFillDate
            const { reportDate } = financeData
            if (reportDate) {
                const [year, mon] = reportDate.split('.')
                return `${year}年${mon}月`
            } else {
                return null
            }
        }
    },
    watch: {
        currentStep: {
            handler() {
                const { currentStep, financeLeftArr, financeData, financeRightArr, cacheCalculate, isShowLook } = this
                if (currentStep === 0) {
                    this.form = {
                        dataList: financeLeftArr,
                        financeRightArr
                    }
                } else {
                    this.form = {
                        dataList: financeData[dataObj[currentStep].key]
                    }
                }
                // 不是查看时才走下面代码
                if (!isShowLook) {
                    // 未命中缓存
                    !cacheCalculate[currentStep] && this.getCalculateInfo()
                    this.$nextTick(() => {
                        this.getInputInfo()
                    })
                }
            }
        },
        financeData: {
            handler(val) {
                const { financeAddBOList } = val
                const { showRed } = this
                if (showRed) {
                    this.changeDataForRed()
                }
                const { left, right } = this.divideData(financeAddBOList)

                // 初始化form
                this.form = {
                    dataList: this.financeLeftArr = left,
                    financeRightArr: this.financeRightArr = right
                }

                // 数据改变 缓存重新计算
                this.cacheCalculate = {}
                this.getCalculateInfo()
            },
            immediate: true
        }
    },
    mounted() {
        if (!this.isShowLook) {
            // window.addEventListener('keyup', this.keyEvent)
            this.getInputInfo()
        }
    },
    destroyed() {
        // !this.isShowLook && window.removeEventListener('keyup', this.keyEvent)
    },
    methods: {
        /*
        * 获取当前财报的input信息
        * 切换步骤 走缓存
        * */
        getInputInfo() {
            const { currentStep, cacheInput } = this
            if (cacheInput[currentStep]) return // 命中缓存
            const allInput = document.querySelectorAll('.fill-finance-info input')

            const inputWeakMap = new WeakMap()
            const inputObj = {}
            let index = 0
            for (let i = 0; i < allInput.length; i++) {
                const inputItem = allInput[i]
                if (!inputItem.disabled) { // 过滤掉disabled的input
                    inputWeakMap.set(inputItem, index) // 方便通过input节点信息获取该索引
                    inputObj[index] = inputItem // 方便通过索引获取input节点
                    index += 1
                }
            }
            inputObj.length = index
            cacheInput[currentStep] = {
                inputWeakMap,
                inputObj
            }
        },
        /**
         * 获取自动计算信息
         * 切换步骤 走缓存
         * 切换日期数据改变重新计算
         */
        getCalculateInfo() {
            const { currentStep, cacheCalculate, financeData, isShowLook } = this
            if (isShowLook) return
            // if (cacheCalculate[currentStep]) return // 命中缓存
            // const {financeAddBOList, cashValueAddBOList, incomeValueAddBOList} = financeData
            let map = {}
            let sumArr = []
            const currentKey = dataObj[currentStep].key
            const currentData = financeData[currentKey]
            currentData.forEach(item => {
                const { coding, nodeType } = item
                map[coding] = item
                if (nodeType === FINANCIAL_SUM) { // 总计
                    sumArr.push(item)
                }
            })
            cacheCalculate[currentStep] = {
                sumArr,
                map
            }
        },
        keyEvent(e) {
            // 按c 自动计算
            if (e.keyCode === 67) {
                try {
                    this.handleCalculate()
                } catch (e) {
                    console.log(e)
                }
            }
        },
        /**
         * 按键事件：回车键,c
         * */
        enterCallback(e) {
            // 按下enter键
            if (e.keyCode === 13) {
                const { currentStep, cacheInput } = this
                const { inputWeakMap, inputObj } = cacheInput[currentStep]
                const activeEl = document.activeElement
                const currentFocusIndex = inputWeakMap.get(activeEl)// 当前焦点索引位置
                if (currentFocusIndex === undefined || currentFocusIndex === inputObj.length - 1) return
                inputObj[currentFocusIndex]?.blur()
                inputObj[currentFocusIndex + 1]?.focus()
            }
        },
        /**
         * 自动替换千分符或者字母
         * */
        handleInputKeyup(e, dataKey, index, model) {
            const reg = /[,，a-zA-Z\s]/g
            const value = e.target.value
            if (reg.test(value)) {
                this.form[dataKey][index][model] = value.replace(reg, '')
            }
            this.enterCallback(e)
        },
        // 需要标红时对数据处理
        changeDataForRed() {
            const { financeAddBOList, cashValueAddBOList, incomeValueAddBOList } = this.financeData
            financeAddBOList.forEach(item => {
                this.$set(item, 'oldValue', item.closingingBalance)
                item.openingBalance = item.closingingBalance
                item.closingingBalance = ''
            })

            let arr = [...cashValueAddBOList, ...incomeValueAddBOList]
            arr.forEach(item => {
                this.$set(item, 'oldValue', item.currentPeriod)
                item.priorPeriod = item.currentPeriod
                item.currentPeriod = ''
            })
        },
        // 输入框改变标红
        changeRightInput(val, item) {
            const { showRed, rightModel } = this
            if (!showRed) return false
            item.showRed = +item.oldValue !== +item[rightModel]
        },
        /**
         根据nodeType 判断是否显示input
         * */
        showInput(item) {
            const { nodeType } = item
            return nodeType === FINANCIAL_OBJECT || nodeType === FINANCIAL_SUM
        },
        notInput(item) {
            const { nodeType } = item
            return nodeType === FINANCIAL_INIT
        },
        isSameInput(item) {
            const { nodeType } = item
            return nodeType === FINANCIAL_SUM
        },
        isDisabled(item) {
            const { isShowLook, isSameInput } = this
            return isShowLook || isSameInput(item)
        },
        handleFillZero() {
            const { form, currentStep, financeRightArr } = this
            this.fillZeroFn(form.dataList)
            if (currentStep === 0) {
                this.fillZeroFn(financeRightArr)
            }
        },
        /**
         * 一键填0
         * */
        fillZeroFn(arr) {
            const { leftModel, rightModel, notInput, isSameInput } = this
            arr.forEach(item => {
                // nodeType为0不赋值
                if (notInput(item) || isSameInput(item)) return
                if (item && !item[leftModel]) {
                    item[leftModel] = 0
                }
                if (item && !item[rightModel]) {
                    item[rightModel] = 0
                }
            })
        },
        /**
         * 提交校验
         * */
        validate() {
            let res = null
            const { form, currentStep, leftModel, rightModel } = this
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    res = true
                    // 需要遍历的数组
                    let mapArr = []
                    if (currentStep !== 0) {
                        mapArr = form.dataList
                    } else {
                        mapArr = [...form.financeRightArr, ...form.dataList]
                    }
                    // 找到有值其余补0
                    const findRes = mapArr.find(item => {
                        const leftValue = item[leftModel]
                        const rightValue = item[rightModel]
                        return leftValue || leftValue === 0 || (rightValue || rightValue === 0)
                    })
                    findRes && this.fillZeroFn(mapArr)
                }
            })
            return res
        },
        /**
         * 自动计算
         * */
        handleCalculate() {
            const { cacheCalculate, currentStep } = this
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const { sumArr, map } = cacheCalculate[currentStep]
                    sumArr.forEach(item => {
                        this.calculateFn(item, map)
                    })

                }
            })
        },
        /**
         * 计算单个总和
         * */
        calculateFn(obj, map) {
            const { leftModel, rightModel, parseFormula, getSum } = this
            const { calculateFormula, coding } = obj

            const leftRes = parseFormula(calculateFormula, leftModel, map)
            const rightRes = parseFormula(calculateFormula, rightModel, map)

            // 公式中包含数字就计算 否则就是为null

            map[coding][leftModel] = getSum(leftRes)
            map[coding][rightModel] = getSum(rightRes)
        },
        getSum(res) {
            // eslint-disable-next-line no-eval
            return /\d/.test(res) ? parseFloat(eval(res).toFixed(2)) : null
        },
        /**
         * 根据code公式得到计算数值  例:a+b-c==>1+2-3
         * */
        parseFormula(formula, type, map) {
            return formula.replace(/(\$\{(.+?)\})/g, (i, a, b) => {
                const v = map[b][type]
                return v === '' ? null : v // 用户输入后清除 此时的value为'',为防止计算出错置为null
            }).replace(/--/g, '+')
        },
        /**
         * 平分数据
         * */
        divideData(data) {
            let dataMap = {}
            data.forEach(item => {
                dataMap[item.sort] = item
            })
            const sum = data[data.length - 1].sort // 总数（带空的）
            const middle = sum / 2 // 界限索引
            let resArr = []
            let index = 0
            for (let i = 1; i <= sum; i++) {
                if (dataMap[i]) {
                    resArr.push(data[index++])
                } else {
                    resArr.push('')
                }
            }
            return {
                left: resArr.splice(0, middle),
                right: resArr
            }
        }
    }
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';

.fill-finance-info {

    overflow-x: scroll;

    .ant-descriptions-title {
        text-align: center;
    }

    &__container {
        display: flex;

        table {
            width: 50%;
        }
    }

    &__thead {
        background-color: $custom-table-title-background-color;

        tr {
            line-height: $custom-table-thead-height;

            th {
                text-align: left;
                text-indent: 10px;
            }
        }
    }

    tr {
        height: 45px;
        border-bottom: 1px solid #ebedf0;
    }

    td {
        //max-width: 200px;
        //padding: 6px 10px;
        white-space: pre-wrap;
        word-wrap: break-word;
        height: 50px;
        max-height: 50px;
    }

    th,
    td {
        min-width: 240px;
        max-width: 500px;
    }

    th:first-child,
    td:first-child {
        min-width: 350px !important;
    }

    .red {
        color: red
    }

    .ant-form {
        display: flex;
    }

    .minWidth {
        min-width: 350px !important;
    }

    input {
        width: 120px;
        text-align: right;
    }

    // form校验提示文字位置
    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-form-explain {
        position: absolute;
        margin-top: -30px;
        margin-left: 122px;
        min-width: 50px;
    }
}

.dynamicTable {
    table {
        width: 100% !important;
    }
}

</style>
