<template>
    <drag-resize
        :visible="visible"
        @close="handleClose">
        <basic-info v-if="delay(1)" :info="info" />
        <div>
            <h1 style="font-weight: 600; font-size: 20px">风控信息</h1>
            <risk-control-info-panel v-if="delay(2)" />
        </div>
    </drag-resize>
</template>

<script>
import delay from '@mixins/delay'

export default {
    name: 'BasicInfoModal',
    components: {
        BasicInfo: () => import('@weights/TaskAndOrderCom/BasicInfo'),
        DragResize: () => import('@weights/DragResize'),
        RiskControlInfoPanel: () => import('@weights/TaskAndOrderTabPanel/RiskControlInfoPanel')
    },
    mixins: [delay(2)],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
        handleOk() {
            this.submitService()
        }
    }
}
</script>

<style lang='scss'>

</style>
