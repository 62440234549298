<template>
    <custom-modal
        class="risk-control-audit-modal"
        title="房开配置"
        :visible="visible"
        @close="handleClose">
        <template slot="customFooter">
            <a-button
                typy="primary"
                @click="handleClose">
                取消
            </a-button>
            <a-button
                type="primary"
                :loading="submitLoading"
                @click="handleSubmit()">
                提交
            </a-button>
        </template>
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span: 6}"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="{
                projectUsage: [
                    {required: true, trigger: 'blur', message: '请选择'},]
            }">
            <a-form-model-item label="请选择:" prop="projectUsage">
                <a-radio-group v-model="form.projectUsage">
                    <a-radio
                        v-for="item in PROJECT_USED_ENUM"
                        :key="item.value"
                        :value="item.value">
                        {{ item.label }}
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
        </a-form-model>
    </custom-modal>
</template>

<script>
import { Button as AButton, FormModel as AFormModel, Radio as ARadio } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'

import { PROJECT_USED_ENUM } from '@views/RiskControlProcurement/SubmitOrder/enum'

import setRoomOpenService from '@service/task/setRoomOpenService'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'

export default {
    name: 'SelectRoomOpenModal',
    components: {
        AButton,
        CustomModal,
        AFormModel,
        ARadio,
        ARadioGroup: ARadio.Group,
        AFormModelItem: AFormModel.Item
    },
    props: {
        riskControlNumber: {
            type: String,
            default: ''
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            submitLoading: false, // 提交loading
            PROJECT_USED_ENUM,
            form: {
                projectUsage: ''
            }
        }
    },
    computed: {},
    methods: {
        handleClose() {
            this.$emit('close')
        },
        // 提交
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                }
            })
        },
        async submitService() {
            const { form, riskControlNumber, $message, $captureException } = this
            try {
                this.submitLoading = true
                const params = {
                    ...form,
                    riskControlNumber
                }
                await setRoomOpenService(params)
                $message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                $message.error(e.message)
                $captureException(e.message)
            } finally {
                this.submitLoading = false
            }
        }
    }
}
</script>

<style lang='scss'>
.risk-control-audit-modal {
  .ant-btn {
    width: 94px;
    height: 32px;
    text-align: center;
  }
}

</style>
