<template>
    <descriptions-with-head
        class="add-financial-head-form-pan"
        title="财报信息">
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol">
            <a-row>
                <a-col :span="8">
                    <a-form-model-item label="填报日期" prop="yearDate">
                        <a-select
                            v-model="form.yearDate"
                            :disabled="isDisabled"
                            style="width: 100px; margin-right: 5px"
                            placeholder="年"
                            @change="handleChange">
                            <a-select-option
                                v-for="item in yearDate"
                                :key="item"
                                :value="item">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                        <a-select
                            v-model="form.monthDate"
                            :disabled="isDisabled"
                            style="width: 100px"
                            placeholder="月"
                            @change="handleChange">
                            <a-select-option
                                v-for="item in monthDate"
                                :key="item"
                                :value="item">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="是否审计" prop="isAudit">
                        <a-select
                            v-model="form.isAudit"
                            :disabled="isDisabled"
                            style="width: 200px"
                            placeholder="请选择">
                            <a-select-option
                                v-for="item in FINANCE_REPORT_AUDIT_ENUM_LIST"
                                :key="item.value"
                                :value="item.value">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
        </a-form-model>
    </descriptions-with-head>
</template>

<script>
import {
    Select as ASelect, FormModel as AFormModel, Row as ARow,
    Col as ACol
} from 'ant-design-vue'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import { FINANCE_REPORT_AUDIT_ENUM_LIST } from '@constant/enum'
import { yearDate, monthDate } from './core'

export default {
    name: 'HeadFormPanel',
    components: {
        ASelect,
        ASelectOption: ASelect.Option,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        DescriptionsWithHead,
        ARow,
        ACol
    },
    props: {
        financeData: {
            type: Object,
            default() {
                return {}
            }
        },
        // 查看
        isShowLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            FINANCE_REPORT_AUDIT_ENUM_LIST,
            yearDate,
            monthDate,
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            form: {
                yearDate: undefined,
                monthDate: undefined,
                isAudit: undefined
            },
            masterOption: null, // 风险对象选择的item,
            categoryOption: null, // 风险分类选择的item,
            rules: {
                yearDate: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择'
                    }
                ],
                isAudit: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择'
                    }
                ]
            }
        }
    },
    computed: {
        params() {
            const { form } = this
            let params = JSON.parse(JSON.stringify(form))
            const y = this.form.yearDate
            const m = this.form.monthDate || 12
            params.reportDate = `${y}.${m}`
            delete params.yearDate
            delete params.monthDate
            return params
        },
        isDisabled() {
            const { isShowLook } = this
            return isShowLook || Boolean(this.$route.query.id)
        }
    },
    watch: {
        financeData: {
            handler(val) {
                if (!val) return
                const { isAudit, reportDate } = val
                if (reportDate) {
                    const [yearDate, monthDate] = reportDate.split('.')
                    this.form.isAudit = isAudit
                    this.form.yearDate = yearDate
                    this.form.monthDate = monthDate
                }
            },
            immediate: true
        }
    },
    created() {
    },
    methods: {
        handleChange() {
            const { yearDate, monthDate } = this.form
            const { reportDate } = this.params
            const year = yearDate ? `${yearDate}年` : ''
            const month = monthDate ? `${monthDate}月` : ''
            const res = year + month
            this.$emit('change', res, reportDate)
        },
        handleFormValidate() {
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    res = this.params
                }
            })
            return res
        }
    }
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';

.add-financial-head-form-pan {
  background-color: #fff;
  min-width: $min-width;
}
</style>
