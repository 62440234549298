import {
    RISK_FACTOR_DATA_TYPE_TXT_TYPE, RISK_FACTOR_DATA_TYPE_DICT_TYPE, RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE,
    RISK_FACTOR_DATA_TYPE_BOOL_TYPE, RISK_FACTOR_DATA_TYPE_ADDRESS_TYPE,
    RISK_FACTOR_DATA_TYPE_DATE_TYPE, RISK_FACTOR_DATA_TYPE_DATE_RANGE_TYPE, RISK_FACTOR_DATA_TYPE_TEL_TYPE,
    RISK_FACTOR_DATA_TYPE_PHONE_TYPE, RISK_FACTOR_DATA_TYPE_PID_TYPE, RISK_FACTOR_DATA_TYPE_CNO_TYPE,
    RISK_FACTOR_DATA_TYPE_MAIL_TYPE, RISK_FACTOR_DATA_TYPE_MONEY_TYPE, RISK_FACTOR_DATA_TYPE_FLOAT_TYPE,
    RISK_FACTOR_DATA_TYPE_NUM_TYPE, RISK_FACTOR_DATA_TYPE_URL_TYPE
} from '@constant/enum'
import {
    validateEmail, validateSocialCreditCode, validateLandline,
    validateIDCard, validateNumber, validatePositiveNumber, customFloatFn
} from '@constant/validates'

const format = 'YYYY-MM-DD'
export const DATA_TYPE = {
    [RISK_FACTOR_DATA_TYPE_TXT_TYPE]: {
        type: 'input',
        message: '请输入',
        validate: [
            {
                required: true,
                message: '请输入'
                // type:'number',
            }, {
                validator: (rule, value, callback) => {
                    if (value && value.length <= 500) {
                        callback()
                    } else {
                        callback(new Error(`字数限制500`))
                    }
                }
            }
        ]
    },
    [RISK_FACTOR_DATA_TYPE_URL_TYPE]: {
        type: 'input',
        message: '请输入',
        validate: [
            {
                required: true,
                message: '请输入'
            }
        ]
    },
    [RISK_FACTOR_DATA_TYPE_DICT_TYPE]: {
        type: 'select',
        message: '请选择',
        validate: [
            {
                required: true,
                message: '请选择'
                // type:'number',
            }
        ]
    },
    // DICT 级联
    [RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE]: {
        // type: 'select',
        type: 'DictCascader',
        message: '请选择',
        validate: [
            {
                required: true,
                message: '请选择'
                // type:'number',
            }
        ]
    },
    // 地区
    [RISK_FACTOR_DATA_TYPE_ADDRESS_TYPE]: {
        validate: [
            {
                required: true,
                message: '请选择'
            }
        ],
        type: 'AreaCascader',
        message: '请选择',
        props: {
            showCounty: false
        }
    },
    [RISK_FACTOR_DATA_TYPE_BOOL_TYPE]: {
        type: 'radio',
        message: '请选择',
        options: [
            {
                label: '是',
                value: '1'
            }, {
                label: '否',
                value: '0'
            }
        ],
        validate: [
            {
                required: true,
                message: '请选择'
            }
        ]
    },
    // 单日时间
    [RISK_FACTOR_DATA_TYPE_DATE_TYPE]: {
        type: 'DatePicker',
        message: '请选择',
        props: {
            format
        },
        validate: [
            {
                required: true,
                message: '请选择'
            }
        ]
    },
    // 时间区间
    [RISK_FACTOR_DATA_TYPE_DATE_RANGE_TYPE]: {
        type: 'DatePicker',
        message: '请选择',
        props: {
            type: 'range',
            format
        },
        validate: [
            {
                required: true,
                message: '请选择'
            }
        ]
    },
    // 座机
    [RISK_FACTOR_DATA_TYPE_TEL_TYPE]: {
        type: 'input',
        message: '请输入',
        validate: [
            {
                // type:'number',
                required: true,
                message: '请输入'
            }, {
                validator: validateLandline
            }
        ]
    },
    // 手机
    [RISK_FACTOR_DATA_TYPE_PHONE_TYPE]: {
        type: 'input',
        message: '请输入',
        validate: [
            {
                // type:'number',
                required: true,
                message: '请输入'
            }, {
                validator: validateLandline
            }
        ]
    },
    // 身份证
    [RISK_FACTOR_DATA_TYPE_PID_TYPE]: {
        type: 'input',
        message: '请输入',
        validate: [
            {
                // type:'number',
                required: true,
                message: '请输入'
            }, {
                validator: validateIDCard
            }
        ]
    },
    // 统一社会信用代码
    [RISK_FACTOR_DATA_TYPE_CNO_TYPE]: {
        type: 'input',
        message: '请输入',
        validate: [
            {
                // type:'number',
                required: true,
                message: '请输入'
            }, {
                validator: validateSocialCreditCode
            }
        ]
    },
    // 邮箱
    [RISK_FACTOR_DATA_TYPE_MAIL_TYPE]: {
        type: 'input',
        message: '请输入',
        validate: [
            {
                // type:'number',
                required: true,
                message: '请输入'
            }, {
                validator: validateEmail
            }
        ]
    },
    // 金额
    [RISK_FACTOR_DATA_TYPE_MONEY_TYPE]: {
        type: 'input',
        message: '请输入',
        validate: [
            {
                required: true,
                message: '请输入'
                // type:'number', ^[0-9]*$
            }, {
                validator: validateNumber
            }
        ]
    },
    // 正整数
    [RISK_FACTOR_DATA_TYPE_NUM_TYPE]: {
        type: 'input',
        message: '请输入',
        validate: [
            {
                required: true,
                message: '请输入'
                // type:'number', ^[0-9]*$
            }, {
                validator: validatePositiveNumber
            }
        ]
    },
    // 浮点数
    [RISK_FACTOR_DATA_TYPE_FLOAT_TYPE]: {
        type: 'input',
        message: '请输入',
        validate(fieldPrecision) {
            return [
                {
                    required: true,
                    message: '请输入'
                }, {
                    validator: customFloatFn(fieldPrecision)
                }
            ]
        }
    },
    CREDIT_REPORT: {
        type: 'input',
        message: '请输入'
    },
    FINANCIAL_REPORT: {
        type: 'input',
        message: '请输入'
    }
}

export const isTxt = function (dataType) {
    return dataType === RISK_FACTOR_DATA_TYPE_TXT_TYPE
}
export const isDict = function (dataType) {
    return dataType === RISK_FACTOR_DATA_TYPE_DICT_TYPE
}
export const isDictCascader = function (dataType) {
    return dataType === RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE
}
export const isBool = function (dataType) {
    return dataType === RISK_FACTOR_DATA_TYPE_BOOL_TYPE
}
export const isAddress = function (dataType) {
    return dataType === RISK_FACTOR_DATA_TYPE_ADDRESS_TYPE
}

export const isTimeRange = function (dataType) {
    return dataType === RISK_FACTOR_DATA_TYPE_DATE_RANGE_TYPE
}
export const isFloat = function (dataType) {
    return dataType === RISK_FACTOR_DATA_TYPE_FLOAT_TYPE
}
