<!-- 任务详情-风险评估 -->
<template>
    <page-layout :show-loading="true" :loading="loading">
        <steps-container>
            <a-steps
                :current="currentStep"
                label-placement="vertical"
                @change="handleStepChange">
                <a-step
                    v-for="item in assessData"
                    :key="item.id"
                    :title="item.name" />
            </a-steps>
        </steps-container>
        <container-panel v-if="delay(2)" class="risk-control-information__container">
            <template slot="content">
                <!-- 步骤组件 -->
                <fill-assess-info
                    v-for="(item,index) in assessData"
                    v-show="index === currentStep"
                    :key="item.id"
                    :ref="`assessRef${index}`"
                    :index="index"
                    :current-step="currentStep"
                    :assess-data="assessData"
                    :assess-obj="assessObj"
                    @loading="handleLoading"
                    @basic="handleBasic" />
                <a-form-model-item
                    :wrapper-col="{ span: 12, offset: 6 }"
                    style="margin-top: 30px; text-align: center">
                    <a-space>
                        <custom-button
                            v-show="showCancelButton"
                            text="取消"
                            @click="handleCancel" />
                        <custom-button
                            v-show="!showCancelButton"
                            text="上一步"
                            type="default"
                            @click="handlePrev" />
                        <a-button
                            :loading="saveLoading"
                            type="primary"
                            @click="handleSave(true)">
                            保存
                        </a-button>
                        <a-button v-show="!showSubmitButton" type="primary" @click="handleNext">
                            下一步
                        </a-button>
                        <a-button
                            v-show="showSubmitButton"
                            type="primary"
                            :loading="submitLoading"
                            @click="handleSubmit">
                            提交
                        </a-button>
                    </a-space>
                </a-form-model-item>
            </template>
        </container-panel>
        <basic-info-modal
            v-if="delay(4)"
            :visible="visible"
            :info="assessObj.riskOrderDetailDTO"
            @close="visible=false" />
    </page-layout>
</template>

<script>
import {
    FormModel as AFormModel,
    Button as AButton, Space as ASpace, Steps as ASteps
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import StepsContainer from '@components/StepsContainer'
import getRiskAssessService from '@service/task/getRiskAssessService'
import ContainerPanel from '@components/ContainerPanel'

import CustomButton from '@components/Button'
import addOrEditRiskAssessService from '@service/task/addOrEditRiskAssessService'
import saveRiskAssessService from '@service/task/saveRiskAssessService'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'
import delay from '@mixins/delay'
export default {
    name: 'RiskAssess',
    components: {
        StepsContainer,
        PageLayout,
        ASteps,
        AStep: ASteps.Step,
        AFormModelItem: AFormModel.Item,
        AButton,
        CustomButton,
        ContainerPanel,
        ASpace,
        FillAssessInfo: () => import('./FillAssessInfo'), // 表组件
        BasicInfoModal: () => import('./BasicInfoModal')// 基本信息弹窗
    },
    mixins: [delay(4)],
    data() {
        return {
            loading: false, // 页面loading
            submitLoading: false, // 提交按钮loading
            saveLoading: false, // 保存按钮loading
            currentStep: 0, // 当前第几步
            assessObj: {}, // 所有对象数据
            assessData: [], // 财报表数组数据
            visible: false
        }
    },
    computed: {
        showCancelButton() {
            const { currentStep } = this
            return currentStep === 0
        },
        showSubmitButton() {
            const { stepLength, currentStep } = this
            return currentStep === stepLength - 1
        },
        stepLength() {
            return this.assessData.length
        },
        id() {
            return this.$route.query.id
        },
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    watch: {},
    created() {
        this.getData()
    },
    methods: {
        async handleStepChange(idx) {
            await this.handleSave(false)
            this.currentStep = idx
        },
        handleBasic() {
            this.visible = true
        },
        handleLoading(val) {
            this.submitLoading = val
        },
        handleCancel() {
            this.$router.back()
        },
        /**
         * 保存
         */
        async handleSave(val) {
            try {
                const { assessData, currentStep } = this
                this.saveLoading = true
                await saveRiskAssessService(assessData[currentStep])
                val && this.$message.success(ERROR_COMMIT_SUCCESS)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.saveLoading = false
            }
        },
        /**
         * 上一步
         */
        handlePrev() {
            this.currentStep -= 1
        },
        /**
         * 下一步
         */
        async handleNext() {
            this.handleSave(false)
            this.currentStep += 1
        },
        /**
         * 提交
         */
        handleSubmit() {
            const { assessData } = this
            for (let i = 0; i < assessData.length; i++) {
                const res = this.$refs[`assessRef${i}`][0].handleSubmit()
                const { code, index } = res
                if (!code) {
                    this.currentStep = index
                    return
                }
                // 校验到最后一步
                if (index === assessData.length - 1) {
                    const { riskWarningList, underwritingOpinions } = res
                    this.assessObj.riskWarningList = riskWarningList
                    this.assessObj.underwritingOpinions = underwritingOpinions
                    this.handleCommitService()
                }
            }
        },
        async handleCommitService() {
            try {
                const { assessObj } = this
                this.submitLoading = true
                await addOrEditRiskAssessService(assessObj)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$router.back()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.submitLoading = false
            }
        },
        /**
         * 获取評估数据
         * */
        async getData() {
            try {
                this.loading = true
                const { riskControlNumber } = this
                this.assessObj = await getRiskAssessService({
                    riskControlNumber
                })
                this.assessData = this.assessObj.layoutResultDTO
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.risk-control-information__container {
    .ant-btn {
        min-width: $custom-button-width;
        height: $custom-button-height
    }
}


</style>
