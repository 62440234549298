<template>
    <a-form-model
        ref="ruleForm"
        class="confirm-file-form-panel"
        layout="horizontal"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :model="form">
        <a-table
            :columns="columns"
            :data-source="dataList"
            :pagination="false"
            :loading="loading"
            row-key="dataFileCoding">
            <template slot="index" slot-scope="text,action,index">
                <a-form-model-item>
                    {{ +index + 1 }}
                </a-form-model-item>
            </template>
            <template slot="dataFileName" slot-scope="text">
                <a-form-model-item>
                    {{ text }}
                </a-form-model-item>
            </template>
            <template slot="action" slot-scope="text,action,index">
                <a-form-model-item
                    :prop="`orderFileValues[${index}].haveFile`"
                    :rules="[{
                        required: true,
                        message: '请选择',
                        trigger: 'blur',
                    }]">
                    <a-radio-group
                        v-model="form.orderFileValues[index].haveFile"
                        name="radioGroup">
                        <a-radio :value="1">
                            是
                        </a-radio>
                        <a-radio :value="0">
                            否
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
            </template>
        </a-table>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel, Table as ATable, Radio as ARadio
} from 'ant-design-vue'
import getRiskOrderFileService from '@service/task/getRiskOrderFileService'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        key: 'index',
        scopedSlots: {
            customRender: 'index'
        },
        width: 200
    }, {
        title: '文件名称',
        dataIndex: 'dataFileName',
        align: 'left',
        key: 'dataFileName',
        scopedSlots: {
            customRender: 'dataFileName'
        },
        width: 200
    }, {
        title: '是否提供',
        dataIndex: 'action',
        align: 'left',
        key: 'action',
        scopedSlots: {
            customRender: 'action'
        },
        width: 200
    }
]
export default {
    name: 'FormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        ATable,
        ARadio,
        ARadioGroup: ARadio.Group
    },
    data() {
        return {
            form: {},
            dataList: [],
            columns,
            loading: false
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleSubmit() {
            let orderFileValues = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    orderFileValues = this.form.orderFileValues
                }
            })
            return orderFileValues
        },
        async getList() {
            try {
                const { riskControlNumber } = this
                this.form.orderFileValues = await getRiskOrderFileService({
                    riskControlNumber
                })
                this.dataList = this.form.orderFileValues
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }

        }
    }
}
</script>

<style lang='scss'>
.confirm-file-form-panel {
  .ant-table-tbody > tr > td {
    padding: 16px 16px 0;
  }
  .ant-form-item-control {
    line-height: normal;
  }
}
</style>
