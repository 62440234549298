<!-- 任务列表 -->
<template>
    <list-page-layout :loading="loading" :show-loading="true">
        <div slot="header">
            <container-panel>
                <radio-group-panel
                    :radio-data="tabData"
                    :sum="sum"
                    :default-active-key="pendingType"
                    @change="handleRadioGroupChange"
                />
            </container-panel>
            <search-panel @search="handleSearch" @refresh="handleRefresh" />
        </div>
        <container-panel :no-card-body-padding-top="true">
            <template slot="title">任务列表</template>
            <template slot="content">
                <a-table
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :loading="loading"
                    :scroll="scrollConfig"
                    row-key="id"
                    @change="handleTableChange">
                    <template slot="proviceCode" slot-scope="text, record">
                        {{ record.proviceCode + record.cityCode }}
                    </template>
                    <template slot="startTime" slot-scope="text,record">
                        {{ text }}至{{ record.endTime }}
                    </template>
                    <template slot="action" slot-scope="action">
                        <div v-show="showAwaitTodoActionButton || showHaveDownActionButton">
                            <a-button
                                v-show="showRoomOpen(action)"
                                v-auth="TASK_INFO_FORWARD_TASK_BUTTON"
                                type="link"
                                @click="handleSelectRoomOpen(action)">
                                确认
                            </a-button>
                            <a-button
                                v-show="!showRoomOpen(action)"
                                v-auth="TASK_INFO_MENU_CODE"
                                type="link"
                                @click="handleDetail(action)">
                                查看
                            </a-button>
                            <a-button
                                v-show="showAwaitTodoActionButton"
                                v-auth="TASK_INFO_FORWARD_TASK_BUTTON"
                                type="link"
                                @click="handleTaskForward(action)">
                                转发
                            </a-button>
                        </div>
                        <div v-show="showTaskProxyActionButton">
                            <a-button
                                v-auth="TASK_INFO_ACCEPT_TASK_BUTTON"
                                type="link"
                                @click="handleModal(true,'accept',action)">
                                接受
                            </a-button>
                            <a-button
                                v-auth="TASK_INFO_REJECT_TASK_BUTTON"
                                type="link"
                                @click="handleModal(true,'reject',action)">
                                拒绝
                            </a-button>
                        </div>
                    </template>
                </a-table>
            </template>
        </container-panel>
        <accept-or-reject-modal
            :visible="visible"
            :type="modalType"
            :info="info"
            @cancel="handleModal(false)"
            @success="handleModalSuccess" />
        <task-forward-modal
            :visible="forwardVisible"
            :info="info"
            @cancel="handleForwardModal"
            @success="handleForwardModal(true)" />
        <!-- 选择房开非房开 -->
        <select-room-open-modal
            v-bind="selectRoomOpenModal"
            @close="selectRoomOpenModal.visible=false"
            @success="handleSelectRoomOpenModalSuccess"
        />
    </list-page-layout>
</template>
<script>
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import RadioGroupPanel from '@components/RadioGroupPanel'
import SearchPanel from '@weights/TaskAndOrderCom/SearchPanel'

import columns from './columns'

import {
    TASK_INFO_MENU_CODE
} from '@constant/menu_code'
import { TASK_AWAIT_TODO_TYPE, TASK_PROXY_TYPE, TASK_HAVE_DOWN_TYPE } from '@constant/enum'
import {
    TASK_INFO_FORWARD_TASK_BUTTON,
    TASK_INFO_REJECT_TASK_BUTTON,
    TASK_INFO_ACCEPT_TASK_BUTTON
} from '@constant/authEnum/task'
import { DEFAULT_PAGES_SIZE } from '@config'

import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'

import getTaskListService from '@service/task/getTaskListService'
import { open } from '@utils'

export default {
    name: 'RuleApproveList',
    components: {
        AcceptOrRejectModal: () => import('./AcceptOrRejectModal'),
        TaskForwardModal: () => import('./TaskForwardModal'),
        SelectRoomOpenModal: () => import('./SelectRoomOpenModal'),
        SearchPanel,
        ListPageLayout,
        ContainerPanel,
        RadioGroupPanel,
        AButton,
        ATable
    },
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            TASK_INFO_FORWARD_TASK_BUTTON,
            TASK_INFO_ACCEPT_TASK_BUTTON,
            TASK_INFO_REJECT_TASK_BUTTON,
            TASK_INFO_MENU_CODE,
            columns,
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            forwardVisible: false, // 转发任务
            info: {},
            modalType: '', // 接收或者拒绝任务
            pendingType: TASK_AWAIT_TODO_TYPE,
            params: {},
            scrollConfig: {
                x: 1100,
                y: 500
            },
            tabData: [
                {
                    key: TASK_AWAIT_TODO_TYPE,
                    label: '我的待办'
                }, {
                    key: TASK_PROXY_TYPE,
                    label: '任务代理'
                }, {
                    key: TASK_HAVE_DOWN_TYPE,
                    label: '已办任务'
                }
            ],
            sum: '',
            selectRoomOpenModal: {}
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        /**
         * 我的代办 操作栏的按钮
         * */
        showAwaitTodoActionButton() {
            const { pendingType } = this
            return pendingType === TASK_AWAIT_TODO_TYPE
        },
        /**
         * 任务代理 操作栏的按钮
         * */
        showTaskProxyActionButton() {
            const { pendingType } = this
            return pendingType === TASK_PROXY_TYPE
        },
        /**
         * 已办任务 操作栏的按钮
         * */
        showHaveDownActionButton() {
            const { pendingType } = this
            return pendingType === TASK_HAVE_DOWN_TYPE
        }
    },
    created() {
        this.getList()
    },
    methods: {
        /**
         * allowSetting
         * 为1表示未确认过是房开还是非房开项目 不显示查看 显示确认按钮
         * 为0表示确认过是房开还是非房开项目 显示查看 不显示确认按钮
         * */
        showRoomOpen({ allowSetting }) {
            return allowSetting === 1
        },
        handleModal(isShow, modalType, action) {
            this.visible = isShow
            if (!isShow) return
            this.modalType = modalType
            this.info = action
        },
        // 同意或者拒绝成功
        handleModalSuccess() {
            this.visible = false
            this.getList()
        },
        /**
         * 设置房开成功回调
         * */
        handleSelectRoomOpenModalSuccess() {
            this.selectRoomOpenModal.visible = false
            this.getList()
        },
        handleForwardModal(val) {
            this.forwardVisible = false
            if (val) {
                this.getList()
            }
        },
        handleTaskForward(val) {
            this.forwardVisible = true
            this.info = val
        },
        handleSelectRoomOpen({ riskControlNumber }) {
            this.selectRoomOpenModal = {
                visible: true,
                riskControlNumber
            }
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        async getList() {
            try {
                this.sum = ''
                this.loading = true
                const { current, limit, params, pendingType } = this
                const param = {
                    currentPage: current,
                    pageSize: limit,
                    ...params,
                    pendingType
                }
                const result = await getTaskListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
                this.sum = this.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        handleExport() {
        },
        handleDetail(action) {
            const { riskControlNumber } = action
            const { showHaveDownActionButton } = this

            open.call(this, 'TaskInfo', {
                riskControlNumber,
                finish: showHaveDownActionButton ? 1 : 0
            })
        },
        handleRadioGroupChange(e) {
            this.pendingType = e.target.value
            this.current = 1
            this.getList()
        }
    }
}
</script>
<style lang="scss">
</style>
