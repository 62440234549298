<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        :model="form"
        :rules="rules">
        <div style="margin-bottom: 18px">修改前风险评分：{{ info.score }}<span style="margin-left: 10px" />{{ info.riskLevelDesc }}</div>
        <div style="margin-bottom: 18px">修改后风险评分：{{ modifyInfo.modifyScore }}<span style="margin-left: 10px" />{{ modifyInfo.modifyRiskLevelDesc }}</div>
        <a-form-model-item label="修改备注" prop="remark">
            <a-input
                v-model="form.remark"
                :max-length="INPUT_MAX_LENGTH"
                placeholder="请输入修改备注" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel,
    Input as AInput
} from 'ant-design-vue'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'
import { INPUT_MAX_LENGTH } from '@constant/Const'

import editRiskScoreValueService from '@service/task/editRiskScoreValueService'
import previewScoreService from '@service/task/previewScoreService'

export default {
    name: 'FormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput
    },
    props: {
        mode: {
            type: String,
            default() {
                return ''
            }
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            modifyInfo: {},
            form: {
                remark: ''
            },
            rules: {
                remark: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入修改备注'
                    }
                ]
            }
        }
    },
    watch: {
        info: {
            handler() {

            },
            immediate: true
        }
    },
    created() {
        this.getPreviewScore()
    },
    methods: {
        handleSubmit() {

            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                }
            })
        },
        async getPreviewScore() {
            try {
                const { info } = this
                this.modifyInfo = await previewScoreService(info)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async submitService() {
            try {
                const { form, info } = this
                const params = {
                    ...form,
                    ...info
                }
                this.$emit('loading', true)
                await editRiskScoreValueService(params)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
