<template>
    <custom-modal
        title="任务转发"
        :width="560"
        :visible="visible"
        @close="handleCancel">
        <template slot="customFooter">
            <a-button key="reset" @click="handleCancel">
                取消
            </a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                确认提交
            </a-button>
        </template>
        <form-panel
            ref="formPanel"
            :info="info"
            @loading="handleLoading"
            @success="handleSuccess" />
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
export default {
    name: 'TaskForwardModal',
    components: {
        AButton,
        CustomModal,
        FormPanel: () => import('./FormPanel')
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        handleLoading(value) {
            this.loading = value
        },
        handleSuccess() {
            this.loading = false
            this.$emit('success')
        },
        handleOk() {
            this.$refs.formPanel.handleSubmit()
        }
    }
}
</script>

<style lang='scss'>

</style>
