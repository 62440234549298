<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 24}"
        :wrapper-col="{ span: 24 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="风控报告财务数据显示:" prop="years">
            <a-checkbox-group v-model="form.years">
                <div
                    v-for="item in dataList"
                    :key="item.reportDate">
                    <a-checkbox
                        :value="item.reportDate"
                        :name="item.reportDate">
                        {{ item.reportDate }}
                    </a-checkbox>
                    <br>
                </div>
            </a-checkbox-group>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel, Checkbox as ACheckbox
} from 'ant-design-vue'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'
import getYearFinancialReportService from '@service/task/getYearFinancialReportService'
import addSelectYearFinancialReportService from '@service/task/addSelectYearFinancialReportService'
export default {
    name: 'FormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        ACheckbox,
        ACheckboxGroup: ACheckbox.Group
    },
    data() {
        return {
            dataList: [],
            form: {
                years: []
            },
            rules: {
                years: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择'
                    }
                ]
            }
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        params() {
            const { form, riskControlNumber } = this
            let params = JSON.parse(JSON.stringify(form))

            params.financialReportYears = params.years.sort().join(',')
            params.riskControlNumber = riskControlNumber
            params.isHaveFinancialReport = true
            delete params.years
            return params
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleSubmit() {
            const { dataList } = this
            if (!dataList.length) {
                this.$message.warning('暂无报表数据')
                return
            }
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                }
            })
        },
        async getList() {
            try {
                this.loading = true
                const { riskControlNumber } = this
                this.dataList = await getYearFinancialReportService({
                    riskControlNumber
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        async submitService() {
            const { params } = this
            try {
                this.$emit('loading', true)
                await addSelectYearFinancialReportService(params)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang='scss'>
.add-edit-category-form-panel {
  .ant-form-item {
    display: block;
  }
}
</style>
