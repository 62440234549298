const date = new Date()
let thisYear = date.getFullYear()
function getYearData() {
    let res = []
    for (let i = thisYear; i >= 1800; i--) {
        res.push(thisYear--)
    }
    return res
}
function getMonthData() {
    let res = []
    for (let i = 1; i <= 12; i++) {
        res.push(i)
    }
    return res
}
const yearDate = getYearData()
const monthDate = getMonthData()
export { yearDate, monthDate }
