var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-layout',{staticClass:"edit-risk-grade",attrs:{"show-loading":true,"loading":_vm.loading}},[_c('descriptions-with-head',{staticClass:"edit-risk-grade__description",attrs:{"title":`单号：${_vm.riskControlNumber}`}},[_c('a-form-model',{ref:"ruleForm",attrs:{"layout":"horizontal","label-col":{ span:4},"wrapper-col":{ span: 16 },"model":_vm.form}},[_c('div',{staticClass:"edit-risk-grade__head"},[_c('span',[_vm._v("风险评分评级:")]),_c('span',{staticClass:"edit-risk-grade__head__right"},[_c('span',{staticClass:"edit-risk-grade__head__right__num"},[_vm._v(_vm._s(_vm.form.score))]),_vm._v(_vm._s(_vm.form.riskLevelDesc)+" ")]),_c('a-button',{directives:[{name:"auth",rawName:"v-auth",value:(_vm.LOOK_MORE),expression:"LOOK_MORE"}],attrs:{"type":"link"},on:{"click":function($event){_vm.historyVisible=true}}},[_vm._v("查看更多")])],1),_c('table',{staticStyle:{"width":"100%"}},[_c('thead',{staticClass:"edit-risk-grade__thead"},[_c('tr',[_c('th',[_vm._v("风险对象")]),_c('th',[_vm._v("评分系数")]),_c('th',[_vm._v("选择属性")])])]),_c('tbody',[(_vm.delay(2))?_vm._l((_vm.projectFactorValueList),function(item,index){return _c('tr',{key:item.id},[_c('td',[_c('a-form-model-item',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(index===0),expression:"index===0"}]},[_vm._v(" 项目 ")])])],1),_c('td',[_c('a-form-model-item',[_vm._v(" "+_vm._s(item.factorName)+" ")])],1),_c('td',[_c('a-form-model-item',{attrs:{"prop":`projectFactorValueList[${index}].riskControlAdjustmentValue`,"rules":{
                                        required: true,
                                        message: '请输入',
                                        trigger: 'blur',}}},[(_vm.isDict(item.dataType))?_c('dict-select',{attrs:{"params":item},model:{value:(item.riskControlAdjustmentValue),callback:function ($$v) {_vm.$set(item, "riskControlAdjustmentValue", $$v)},expression:"item.riskControlAdjustmentValue"}}):_c('a-input',{attrs:{"type":"text"},model:{value:(item.riskControlAdjustmentValue),callback:function ($$v) {_vm.$set(item, "riskControlAdjustmentValue", $$v)},expression:"item.riskControlAdjustmentValue"}})],1)],1)])}):_vm._e(),(_vm.delay(3))?_vm._l((_vm.enterpriseFactorValueList),function(item,index){return _c('tr',{key:item.id},[_c('td',[_c('a-form-model-item',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(index===0),expression:"index===0"}]},[_vm._v("企业")])])],1),_c('td',[_c('a-form-model-item',[_vm._v(" "+_vm._s(item.factorName)+" ")])],1),_c('td',[_c('a-form-model-item',{attrs:{"prop":`enterpriseFactorValueList[${index}].riskControlAdjustmentValue`,"rules":{
                                        required: true,
                                        message: '请输入',
                                        trigger: 'blur',}}},[(_vm.isDict(item.dataType))?_c('dict-select',{attrs:{"params":item},model:{value:(item.riskControlAdjustmentValue),callback:function ($$v) {_vm.$set(item, "riskControlAdjustmentValue", $$v)},expression:"item.riskControlAdjustmentValue"}}):_c('a-input',{attrs:{"type":"text"},model:{value:(item.riskControlAdjustmentValue),callback:function ($$v) {_vm.$set(item, "riskControlAdjustmentValue", $$v)},expression:"item.riskControlAdjustmentValue"}})],1)],1)])}):_vm._e()],2)]),_c('a-form-model-item',{staticStyle:{"margin-top":"30px","text-align":"center"},attrs:{"wrapper-col":{ span: 12, offset: 6 }}},[_c('a-space',[_c('custom-button',{attrs:{"text":"取消"},on:{"click":_vm.handleCancel}}),_c('custom-button',{attrs:{"text":"提交","type":"primary","loading":_vm.submitLoading},on:{"click":_vm.handleSubmit}})],1)],1)],1)],1),(_vm.delay(4))?_c('submit-tips-modal',{attrs:{"visible":_vm.visible,"info":_vm.form},on:{"cancel":function($event){_vm.visible=false},"success":_vm.handleSuccess}}):_vm._e(),(_vm.delay(5))?_c('history-score-modal',{attrs:{"visible":_vm.historyVisible},on:{"cancel":function($event){_vm.historyVisible=false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }