<template>
    <div class="welcome-page">
        欢迎使用{{ systemName }}
    </div>
</template>

<script>
export default {
    name: 'WelcomeView',
    computed: {
        systemName() {
            return this.$store.getters.systemName
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.welcome-page {
  font-size: 32px;
  text-align: center;
  margin-top: 100px;
}
</style>
