<!-- 附件类型 -->
<template>
    <div class="attachment-panel">
        <a-form-model-item
            label="附件类型:"
            prop="type"
            :label-col="{ span: 4}"
            :wrapper-col="{ span: 15 }">
            <a-select
                v-model="type"
                style="width: 100%"
                placeholder="请选择">
                <a-select-option
                    v-for="item in FILE_TYPE_ENUM"
                    :key="item.value"
                    :value="item.value">
                    {{ item.label }}
                </a-select-option>
            </a-select>
        </a-form-model-item>

        <file-material
            :data-list="fileData" />
    </div>
</template>

<script>
import { Select as ASelect, FormModel as AFormModel } from 'ant-design-vue'
import getEnterpriseOrProjectFileService from '@service/task/getEnterpriseOrProjectFileService'
import { FILE_TYPE_ENUM } from '@constant/enum'

export default {
    name: 'AttachmentPanel',
    components: {
        AFormModelItem: AFormModel.Item,
        ASelect,
        ASelectOption: ASelect.Option,
        FileMaterial: () => import('@weights/FileMaterial')
    },
    data() {
        return {
            FILE_TYPE_ENUM,
            type: 3,
            fileData: []
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    watch: {
        type: {
            handler() {
                this.getList()
            },
            immediate: true
        }
    },
    methods: {
        handleClick(item) {
            const { filePath } = item
            window.open(filePath)
        },
        async getList() {
            const { riskControlNumber, type } = this
            try {
                this.fileData = await getEnterpriseOrProjectFileService({
                    riskControlNumber,
                    fileType: type
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>
.attachment-panel {
  width: 600px;
  padding: 40px 0 40px 60px;
  border: 1px solid #ebedee;
  margin-right: 20px;
}
</style>
