<!-- 风控信息页面 -->
<template>
    <page-layout class="risk-control-information" :show-loading="true" :loading="loading">
        <steps-container>
            <a-steps
                :current="currentStep"
                label-placement="vertical"
                @change="handleStepChange">
                <a-step
                    v-for="item in dataList"
                    :key="item.name"
                    :title="item.name" />
            </a-steps>
        </steps-container>

        <container-panel class="risk-control-information__container">
            <template slot="content">
                <!-- 步骤组件 -->
                <fill-project-info
                    v-if="dataList.length"
                    ref="fillInfoRef"
                    :current-step="currentStep"
                    :data-list="dataList"
                    :record="dataList[currentStep]"
                    @report="handleReport" />
                <a-form-model-item
                    :wrapper-col="{ span: 12, offset: 6 }"
                    class="risk-control-information__container__btn-group">
                    <div class="">
                        <custom-button
                            v-show="showCancelButton"
                            text="取消"
                            @click="handleCancelButton" />
                        <custom-button
                            v-show="!showCancelButton"
                            text="上一步"
                            type="default"
                            @click="handlePrev" />
                        <custom-button
                            text="保存"
                            type="primary"
                            @click="handleSave(true)" />
                        <a-button
                            v-show="!showSubmitButton"
                            type="primary"
                            @click="handleNext">
                            下一步
                        </a-button>
                        <custom-button
                            v-show="showSubmitButton"
                            text="提交"
                            type="primary"
                            :loading="submitLoading"
                            @click="handleSubmit" />
                    </div>
                </a-form-model-item>
            </template>
        </container-panel>
        <a-back-top />
        <confirm-file-modal
            :visible="visible"
            @cancel="visible=false"
            @success="submitService"
        />
    </page-layout>
</template>

<script>
import {
    Steps as ASteps,
    FormModel as AFormModel,
    Button as AButton, BackTop as ABackTop
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import StepsContainer from '@components/StepsContainer'
import ContainerPanel from '@components/ContainerPanel'
import CustomButton from '@components/Button'

import getRiskControlInfoFormDataService from '@service/task/getRiskControlInfoFormDataService'
import editRiskControlInfoFormDataService from '@service/task/editRiskControlInfoFormDataService'
import saveRiskControlInfoCurrentFormDataService from '@service/task/saveRiskControlInfoCurrentFormDataService'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'
import { LOCAL_SET_STEP } from '@constant/localstorage'
import { formatDataToCreateForm } from './core'
import { isDict, isTimeRange } from './dataType'

export default {
    name: 'RiskControlInformation',
    components: {
        FillProjectInfo: () => import('./FillProjectInfo'),
        ConfirmFileModal: () => import('./ConfirmFileModal'),
        PageLayout,
        ASteps,
        AStep: ASteps.Step,
        StepsContainer,
        ContainerPanel,
        AFormModelItem: AFormModel.Item,
        AButton,
        CustomButton,
        ABackTop
    },
    props: {},
    data() {
        return {
            labelCol: {
                span: 2
            },
            wrapperCol: {
                span: 22
            },
            currentStep: 0, // 当前第几步
            edit: false,
            loading: false, // 页面loading
            submitLoading: false, // 提交按钮loading
            dataList: [],
            rule: [],
            visible: false
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        stepLength() {
            return this.dataList.length
        },
        showCancelButton() {
            const { currentStep } = this
            return currentStep === 0
        },
        showSubmitButton() {
            const { stepLength, currentStep } = this
            return currentStep === stepLength - 1
        }
    },
    created() {
        this.getRiskControlInfoInitFormData()
    },
    methods: {
        async handleStepChange(idx) {
            await this.handleSave()
            this.currentStep = idx
        },
        handleReport(res, index) {
            const { dataList } = this
            dataList[index].enterpriseCreditReport = res
        },
        handleCancelButton() {
            this.$router.back()
        },
        /**
         * 保存
         */
        async handleSave(message) {
            try {
                const { currentStep, dataList } = this
                this.formatCurrentLayout()
                this.$refs.fillInfoRef.handleSave() // 征信报告暂存
                await saveRiskControlInfoCurrentFormDataService(dataList[currentStep])
                message && this.$message.success('保存成功')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        /**
         * 上一步
         */
        handlePrev() {
            this.currentStep -= 1
        },
        /**
         * 下一步
         */
        handleNext() {
            this.handleSave()
            this.currentStep += 1
        },
        /**
        * 解析当前步骤的布局
        * */
        formatCurrentLayout(index) {
            const { currentStep, dataList } = this
            const key = index === undefined ? currentStep : index // 不传参 默认解析当前step布局
            const currentLayout = dataList[key]
            currentLayout.res = this.getLayoutFields(currentLayout)
        },
        getLayoutFields(currentLayout) {
            const { layoutFieldList, isMasterArr } = currentLayout
            const res = []// 收集有riskOrderValue的因子(后台接收的数据)
            // 当前步骤的因子集合数据处理
            layoutFieldList.forEach(item => {
                const { isMaster, dataType, value } = item
                if (isMaster) return // isMaster节点不做操作
                item.riskOrderValue = value
                // DICT处理
                if (isDict(dataType)) {
                    const findMaster = isMasterArr.find(masterItem => masterItem.displayValue === item.value)
                    if (findMaster) {
                        findMaster.childs.forEach(item => {
                            item.riskOrderValue = item.value
                        })
                        res.push(findMaster)
                    }
                }

                // 时间区间处理
                if (isTimeRange(dataType)) {
                    if (value) {
                        item.riskOrderValue = value.join('-')
                    }
                }
                res.push(item)
            })
            return res
        },
        /**
         * 提交校验
         */
        async handleSubmit() {
            await this.handleSave() // 保存当前数据 防止丢失
            const { code, index } = this.$refs.fillInfoRef.handleNext()
            if (!code) { // code=0 检验失败
                this.currentStep = index
                return
            }
            this.visible = true
        },
        async submitService(orderFileValues) {
            const { dataList } = this
            try {
                this.visible = false
                this.submitLoading = true
                await editRiskControlInfoFormDataService({
                    layoutResultDTO: dataList,
                    orderFileValues
                })
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$router.back()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.submitLoading = false
            }
        },
        /**
         * 获取风控信息表单数据 初始化
         */
        async getRiskControlInfoInitFormData() {
            const { riskControlNumber } = this
            // 回显
            try {
                this.loading = true
                this.dataList = await getRiskControlInfoFormDataService({
                    riskControlNumber
                })
                this.dataList.forEach((item, index) => {
                    this.$set(item, 'isSelectReport', false)
                    item.isMasterArr = item.layoutFieldList.filter(item => item.isMaster) || []
                    item.rule = formatDataToCreateForm(item) || []
                    this.formatCurrentLayout(index)
                })

                const step = window.sessionStorage.getItem(LOCAL_SET_STEP)
                if (!step) return
                this.currentStep = +step
                this.cleanStorage()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        },
        cleanStorage() {
            const s = window.sessionStorage.getItem(LOCAL_SET_STEP)
            s && window.sessionStorage.removeItem(LOCAL_SET_STEP)
        }
    },
    beforeRouteLeave(to, from, next) {
        const { currentStep } = this
        if (to.path === '/task-management/task-list/add-financial-report') {
            window.sessionStorage.setItem(LOCAL_SET_STEP, currentStep)
        } else {
            this.cleanStorage()
        }
        next()
    }
}
</script>

<style lang="scss">
@import '~@/assets/styles/varibles.scss';

.risk-control-information {
    .ant-form-item-label {
        max-width: 340px;
        white-space: break-spaces;
        margin-right: 10px;
    }


    .steps-container {
        border-radius: $border-radius-base;
    }

    &__container {
        &-selected-text {
            padding: 0 10px;
            background-color: $border-color;
            border: 1px solid #dcdedf;
            border-radius: 4px;
        }

        // 按钮group
        &__btn-group {
            margin-top: 30px;
            text-align: center;

            .ant-btn {
                width: $custom-button-width;
                margin-right: $custom-button-margin-right;
            }
        }
    }
}
</style>
