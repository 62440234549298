<template>
    <div>
        <head-com
            :risk-order-detail-d-t-o="riskOrderDetailDTO">
            <div slot="headButton">
                <!-- 查看代办任务 -->
                <template v-if="!isFinish">
                    <a-button-group>
                        <!-- 待录入 -->
                        <template v-if="showAwaitEnter">
                            <a-button
                                v-auth="TASK_INFO_RISK_CONTROL_INFORMATION_MENU_CODE"
                                @click="handleLookInformation">
                                数据核验
                            </a-button>
                            <a-button
                                v-auth="TASK_INFO_DOWNLOAD_MATERIAL_BUTTON"
                                @click="handleDownload">
                                下载资料
                            </a-button>
                        </template>
                        <!-- 待初审 -->
                        <template v-if="showAwaitFirstTrial">
                            <a-button
                                v-auth="TASK_INFO_RISK_ASSESS_MENU_CODE"
                                @click="handleRiskAssess">
                                风险评估
                            </a-button>
                            <a-button
                                v-auth="TASK_INFO_DOWNLOAD_MATERIAL_BUTTON"
                                :loading="previewLoading"
                                @click="handlePreviewReport">
                                预览报告
                            </a-button>
                        </template>
                        <!-- 待复审/高审 -->
                        <!--       -->
                        <a-button
                            v-if="showRecheck || showHeight"
                            v-auth="TASK_INFO_DOWNLOAD_MATERIAL_BUTTON"
                            :loading="previewLoading"
                            @click="handlePreviewReport">
                            预览报告
                        </a-button>
                    </a-button-group>
                    <a-dropdown v-if="showDropDown">
                        <a-button
                            class="task-head-container-panel__dropdown-button"
                            @click="e => e.preventDefault()">
                            ...
                        </a-button>
                        <a-menu slot="overlay">
                            <!-- 待初审 -->
                            <template v-if="showAwaitFirstTrial">
                                <!--                <a-menu-item
                                                    :disabled="!buttonPermissionForFinance"
                                                    key="0"
                                                    v-auth="TASK_INFO_SELECT_FINANCE_REPORT_BUTTON"
                                                    @click="handleChooseFinance">
                                                  选择财务数据
                                                </a-menu-item> -->
                                <a-menu-item
                                    key="1"
                                    v-auth="TASK_INFO_EDIT_RISK_GRADE_MENU_CODE"
                                    @click="handleEditRiskGrade">
                                    修改风险评分
                                </a-menu-item>
                                <a-menu-item
                                    key="2"
                                    v-auth="TASK_INFO_EDIT_RISK_CONTROL_BUTTON"
                                    @click="handleEditRiskControlInfo">
                                    修改风控信息
                                </a-menu-item>
                                <a-menu-item
                                    key="3"
                                    v-auth="TASK_INFO_DOWNLOAD_MATERIAL_BUTTON"
                                    @click="handleDownload">
                                    下载资料
                                </a-menu-item>
                            </template>
                            <a-menu-item
                                key="4"
                                v-auth="TASK_INFO_CLOSE_ORDER_BUTTON"
                                @click="handleModal('orderVisible',true)">
                                关闭订单
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>

                    <a-button
                        v-if="showRiskControlButton"
                        v-auth="TASK_INFO_RISK_CONTROL_AUDIT_BUTTON"
                        style="margin-left: 20px"
                        type="primary"
                        @click="handleAudit">
                        风控审核
                    </a-button>
                </template>

                <!--                <a-button
                    v-if="canDownLoadReport"
                    style="margin-left:20px"
                    type="primary"
                    v-auth="TASK_INFO_DOWN_LOAD_REPORT_BUTTON"
                    @click="handleDownLoadReport">下载报告
                </a-button> -->
            </div>
            <slot />
        </head-com>

        <!-- 关闭订单弹窗 -->
        <close-order-modal
            :risk-order-detail-d-t-o="riskOrderDetailDTO"
            :visible="orderVisible"
            @cancel="handleModal('orderVisible',false)"
            @success="handleModal('orderVisible',false,'success')" />
        <!-- 风控审核弹窗 -->
        <risk-control-audit-modal
            :button-permission="buttonPermission"
            :risk-order-detail-d-t-o="riskOrderDetailDTO"
            :visible="auditVisible"
            @reload="handleReload"
            @cancel="handleModal('auditVisible',false)"
            @success="handleModal('auditVisible',false,'success')" />
        <!-- 选择财报弹窗 -->
        <select-financial-modal
            :visible="financialVisible"
            @cancel="handleModal('financialVisible',false)"
            @success="handleModal('financialVisible',false)" />
        <!-- 检查企业弹窗 -->
        <a-modal
            title="提示"
            :visible="enterpriseModal.enterpriseVisible"
            @ok="handleEnterpriseOk"
            @cancel="enterpriseModal.enterpriseVisible=false">
            <p>{{ enterpriseModal.data }}</p>
        </a-modal>
    </div>
</template>

<script>
import {
    Button as AButton, Menu as AMenu,
    Dropdown as ADropdown
} from 'ant-design-vue'
import { ERROR_COMMIT_DELETE } from '@constant/error'
import { orderStateBoolFnObj } from '@constant/function'
import {
    TASK_INFO_DOWNLOAD_MATERIAL_BUTTON, TASK_INFO_RISK_CONTROL_AUDIT_BUTTON,
    TASK_INFO_DOWN_LOAD_REPORT_BUTTON,
    TASK_INFO_CLOSE_ORDER_BUTTON, TASK_INFO_SELECT_FINANCE_REPORT_BUTTON,
    TASK_INFO_EDIT_RISK_CONTROL_BUTTON
} from '@constant/authEnum/task'

import enumOperate from '@mixins/enumOperate'
import deleteCommodityService from '@service/commodityManage/commodityConfig/deleteCommodityService'
import downLoadFileService from '@service/order/downLoadFileService'
import downLoadPdfService from '@service/order/downLoadPdfService'
import previewLoadPdfService from '@service/order/previewLoadPdfService'
import checkEnterpriseService from '@service/task/checkEnterpriseService'
import {
    TASK_INFO_RISK_CONTROL_INFORMATION_MENU_CODE,
    TASK_INFO_EDIT_RISK_GRADE_MENU_CODE, TASK_INFO_RISK_ASSESS_MENU_CODE
} from '@constant/menu_code'

export default {
    name: 'TaskHead',
    components: {
        HeadCom: () => import('@weights/TaskAndOrderCom/HeadCom'),
        AButton,
        AButtonGroup: AButton.Group,
        ADropdown,
        AMenu,
        AMenuItem: AMenu.Item,
        CloseOrderModal: () => import('./CloseOrderModal'),
        RiskControlAuditModal: () => import('./RiskControlAuditModal'),
        SelectFinancialModal: () => import('./SelectFinancialModal')
    },
    mixins: [enumOperate],
    props: {
        buttonPermission: {
            type: Boolean,
            default: false
        },
        buttonPermissionForFinance: {
            type: Boolean,
            default: false
        },
        riskOrderDetailDTO: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            TASK_INFO_EDIT_RISK_CONTROL_BUTTON,
            TASK_INFO_SELECT_FINANCE_REPORT_BUTTON,
            TASK_INFO_EDIT_RISK_GRADE_MENU_CODE,
            TASK_INFO_RISK_CONTROL_INFORMATION_MENU_CODE,
            TASK_INFO_RISK_CONTROL_AUDIT_BUTTON,
            TASK_INFO_DOWN_LOAD_REPORT_BUTTON,
            TASK_INFO_CLOSE_ORDER_BUTTON,
            TASK_INFO_DOWNLOAD_MATERIAL_BUTTON,
            TASK_INFO_RISK_ASSESS_MENU_CODE,
            orderVisible: false, // 关闭订单
            auditVisible: false, // 风控审核
            financialVisible: false, // 选择财报数据
            pdfUrl: '',
            orderStateBoolFnObj,
            enterpriseModal: {
                visible: false,
                data: ''
            },
            previewLoading: false
        }
    },
    computed: {
        /**
         * 是否待录入
         * */
        showAwaitEnter() {
            return orderStateBoolFnObj.isAwaitEnter(this.riskOrderDetailDTO)
        },
        /**
         * 是否待初审
         * */
        showAwaitFirstTrial() {
            return orderStateBoolFnObj.isInitAudit(this.riskOrderDetailDTO)
        },
        /**
         * 是否待复审
         * */
        showRecheck() {
            return orderStateBoolFnObj.isAgainAudit(this.riskOrderDetailDTO)
        },
        /**
         * 是否待高审
         * */
        showHeight() {
            return orderStateBoolFnObj.isHeightAudit(this.riskOrderDetailDTO)
        },
        /**
         * 是否已签发
         * */
        showIsFinish() {
            return orderStateBoolFnObj.isFinish(this.riskOrderDetailDTO)
        },
        // 风控审核
        showRiskControlButton() {
            return orderStateBoolFnObj.isInitState(this.riskOrderDetailDTO.businessState)
        },
        // 可以下载报告
        canDownLoadReport() {
            return orderStateBoolFnObj.canDownLoadReport(this.riskOrderDetailDTO)
        },
        /**
         * DropDown显示
         * */
        showDropDown() {
            const { showAwaitEnter, showAwaitFirstTrial } = this
            return showAwaitEnter || showAwaitFirstTrial
        },
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        // 是否已完成
        isFinish() {
            return this.$route.query.finish === '1'
        }
    },
    methods: {
        handleEnterpriseOk() {
            this.enterpriseModal.enterpriseVisible = false
            this.auditVisible = true
        },
        handleChooseFinance() {
            this.financialVisible = true
        },
        /**
         下载报告
         * */
        async handleDownLoadReport() {
            const { riskControlNumber } = this
            try {
                const href = await downLoadPdfService({
                    riskControlNumber
                })
                window.open(href)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        /**
         * 预览报告
         * */
        async handlePreviewReport() {
            if (this.pdfUrl) { // 缓存
                window.open(this.pdfUrl)
                return
            }
            this.previewLoading = true
            const { riskControlNumber } = this
            try {
                const result = await previewLoadPdfService({
                    riskControlNumber
                })
                if (result) {
                    this.pdfUrl = result
                    window.open(this.pdfUrl)
                }
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.previewLoading = false
            }
        },
        handleEditRiskGrade() {
            this.$router.push({
                name: 'EditRiskGrade',
                query: {
                    riskControlNumber: this.riskControlNumber
                }
            })
        },
        handleEditRiskControlInfo() {
            this.handleLookInformation()
        },
        async handleDownload() {
            const { riskControlNumber } = this
            try {
                await downLoadFileService({
                    riskControlNumber
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        /**
         *点击风控审核
         */
        handleAudit() {
            const { showAwaitEnter } = this
            // v2.1.0 新增待录入确认企业名称
            if (showAwaitEnter) {
                this.checkEnterpriseFn()
            } else {
                this.auditVisible = true
            }
        },
        async checkEnterpriseFn() {
            const { riskControlNumber } = this
            try {
                const data = await checkEnterpriseService({
                    riskControlNumber
                })
                // 有返回值 是否存在企业名称或者信用代码相同
                if (data) {
                    this.enterpriseModal = {
                        enterpriseVisible: true,
                        data
                    }
                } else {
                    this.auditVisible = true
                }
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        handleModal(type, isShow, success) {
            this[type] = isShow
            // 成功返回
            if (success) {
                this.$router.replace({
                    name: 'TaskList'
                })
            }
        },
        handleReload() {
            this.auditVisible = false
            this.$emit('reload')
        },
        handleRiskAssess() {
            this.$router.push({
                name: 'RiskAssess',
                query: {
                    riskControlNumber: this.riskControlNumber
                }
            })
        },
        handleLookInformation() {
            this.$router.push({
                name: 'RiskControlInformation',
                query: {
                    riskControlNumber: this.riskControlNumber
                }
            })
        },
        handleDelete() {
            this.$confirm({
                title: '删除当前模型，将删除当前模型的所有信息，确认删除吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteService()
                },
                onCancel() {
                }
            })
        },
        async deleteService() {
            try {
                const { queryId } = this
                await deleteCommodityService(queryId)
                this.$message.success(ERROR_COMMIT_DELETE)
                this.$router.back()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        }
    }
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';

.task-head-container-panel {

    &__dropdown-button {
        width: 42px !important;
        height: 32px
    }

    .ant-card-head-wrapper {
        align-items: normal;
    }

    &__head-right-desc {
        text-align: right;
        // margin-top: 10px;

        &__label {
            color: #999;
            padding-right: 5px;
        }

        &__value {
            color: #0f0f0f
        }
    }


    .ant-btn {
        margin-right: 0 !important;
    }

    .ant-card-head-title {
        padding-bottom: 0;
    }

    .ant-card-body {
        padding: 0 $ant-card-body-padding 0 $ant-card-body-padding;
    }

    .ant-tabs {
        border-top: 1px dashed $border-color;
    }

    /*.ant-dropdown-menu{
      box-shadow: 0 0 0 #fff;
    }*/
}
</style>
