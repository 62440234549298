import { DATA_TYPE, isDict, isTimeRange, isAddress } from './dataType'
import { showFinAndReportFnObj } from '@constant/function'

// 入口
export const formatDataToCreateForm = function (item) {
    const { layoutFieldList, isMasterArr } = item
    let res = []
    for (let i = 0; i < layoutFieldList.length; i++) {
        const paramItem = layoutFieldList[i]
        const { isMaster } = paramItem
        // 不是受限制节点
        if (!isMaster) {
            res.push(changeFormItemData(paramItem, isMasterArr, item))
        }
    }
    return res
}

/**
 *   需要展示成formItem的
 * 转换成form-create数据格式
 * */
function changeFormItemData(paramItem, isMasterArr = [], item = {}) {
    const { dataType, dtoList = [], riskOrderValue } = paramItem
    // 财报标识因子
    if (showFinAndReportFnObj.showFinancial(paramItem)) {
        item.showFinancial = true
        return
    }// 征信报告标识因子 只作为判断，不作显示
    if (showFinAndReportFnObj.showReport(paramItem)) {
        item.showReport = true
        return
    }

    // 合并初始化数据
    Object.assign(paramItem, initFormCreate(paramItem, item))
    // DICT 还得调取工保云接口
    if (isDict(dataType)) {
        if (riskOrderValue) {
            // 回显的值不在dtoList里  网和盾枚举值不一致
            const has = dtoList.find(item => item.value === riskOrderValue)
            if (!has) {
                paramItem.value = null
            }
        }

        dtoList.forEach(item => {
            const { value, name, id } = item
            if (riskOrderValue === name) { // 兼容工保云回显的中文
                paramItem.value = value
            }
            paramItem.options.push({
                label: name,
                value,
                id
            })
            const findMaster = isMasterArr.find(item => item.displayValue === value)
            if (findMaster) {
                let rule = findMaster.childs.map(item => changeFormItemData(item))
                paramItem.control.push({
                    handle: val => val === value,
                    rule
                })
            }
        })
    }
    return paramItem
}

/**
 * 初始化 form-create 的一些基础字段
 * @param paramItem 需要操作的因子
 * @param item：接口返回数据 dataList的item
 * @returns {}
 */
function initFormCreate(paramItem, item) {
    const {
        dataType, dataVerificationFactorName, riskOrderValue, id, unit, fieldPrecision, dtoList
    } = paramItem

    let dataTypeObj = DATA_TYPE[dataType]
    if (!dataTypeObj) {
        console.log('dataType:' + dataType + '不支持！！！')
        dataTypeObj = {}
    }
    const { validate, type, props = {}, options = [] } = dataTypeObj // dataType.js中相关数据

    const obj = {
        title: dataVerificationFactorName, // 名字
        type: type || 'input',

        field: id, // 定位标识
        col: {
            span: 12
        },
        wrap: {
            labelCol: {
                span: 10
            },
            wrapperCol: {
                span: 10
            }
        },
        props: {
            suffix: unit || '',
            riskOrderValue,
            dtoList,
            ...props
        },
        options,
        control: [],
        update(val, rule, fapi) {
            // console.log(val, rule,fapi)
            const dom = fapi.el(rule.id)
            if (riskOrderValue === null || riskOrderValue === '' || !dom) return
            // 修改项标红
            if (val !== riskOrderValue) {
                dom.$el.style.color = 'red'
                // 带单位的输入框
                if (dom.$refs.input) {
                    dom.$refs.input.style.color = 'red'
                }
            } else {
                dom.$el.style.color = 'black'
                if (dom.$refs.input) {
                    dom.$refs.input.style.color = 'black'
                }
            }
        }
    }

    if (Array.isArray(validate)) {
        obj.validate = validate
    } else if (typeof validate === 'function') { // 浮点数validate...
        obj.validate = validate(fieldPrecision)
    } else {
        obj.validate = []
    }

    // 是否选择征信报告因子  单独给此因子注入事件
    if (showFinAndReportFnObj.isSelectReport(paramItem)) {
        item.isSelectReport = Boolean(+riskOrderValue)
        obj.on = {
            change: e => {
                item.isSelectReport = Boolean(+e.target.value)
            }
        }
    }

    // 时间特殊处理
    if (isTimeRange(dataType)) {

        if (riskOrderValue) {
            const len = riskOrderValue.length
            const mid = Math.floor(len / 2)
            const start = riskOrderValue.slice(0, mid)
            const end = riskOrderValue.slice(mid + 1, len)
            // const [start, end] = riskOrderValue.split('-')
            obj.value = [start, end]
        } else {
            obj.value = [] // 为空的值
        }
    } else if (isAddress(dataType)) { // 地区特殊处理 初始化工保云数据回显中文
        if (/\d+/.test(riskOrderValue)) { // 如果是code
            obj.value = riskOrderValue
        } else { // 中文或者其他
            obj.value = ''
        }
    } else {
        obj.value = riskOrderValue
    }
    return obj
}
