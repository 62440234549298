<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 4}"
        :wrapper-col="{ span: 20 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="审核意见:" prop="remark">
            <a-input
                v-model="form.remark"
                type="textarea"
                :rows="10"
                :max-length="INPUT_MAX_LENGTH_500"
                placeholder="请输入核验意见" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel, Input as AInput
} from 'ant-design-vue'

import { ERROR_COMMIT_SUCCESS } from '@constant/error'
import { INPUT_MAX_LENGTH_500 } from '@constant/Const'

import { auditServeFn } from '@constant/function'

export default {
    name: 'FormPanel',
    components: {
        AFormModel,
        AInput,
        AFormModelItem: AFormModel.Item
    },
    props: {
        riskOrderDetailDTO: {
            type: Object,
            default() {
                return {}
            }
        },
        orderStateBoolFnObj: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH_500,
            form: {
                remark: ''
            },
            rules: {
                remark: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入备注'
                    }
                ]
            }
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        serveFn() {
            const { riskOrderDetailDTO } = this
            return auditServeFn(riskOrderDetailDTO)
        }
    },
    methods: {
        handleSubmit(...args) {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService(...args)
                }
            })
        },
        async submitService(action, loadingName) {
            const { serveFn, form } = this
            try {
                const { riskControlNumber } = this
                const params = {
                    ...form,
                    riskControlNumber,
                    action
                }
                this.$emit('loading', loadingName, true)
                await serveFn(params)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                // 重新加载数据
                this.$emit('reload')
                this.$captureException(e)
            } finally {
                this.$emit('loading', loadingName, false)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
