<template>
    <custom-modal
        title="确认资料"
        :width="800"
        :visible="visible"
        @close="handleCancel">
        <template slot="customFooter">
            <a-button key="reset" @click="handleCancel">
                取消
            </a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                提交
            </a-button>
        </template>
        <form-panel
            ref="formPanel" />
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
export default {
    name: 'ConfirmFileModal',
    components: {
        AButton,
        CustomModal,
        FormPanel: () => import('./FormPanel')
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        handleOk() {
            const orderFileValues = this.$refs.formPanel.handleSubmit()
            if (orderFileValues) {
                this.$emit('success', orderFileValues)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
