<template>
    <descriptions-with-head
        v-if="form"
        :title="form.name"
        style="background-color: #fff">
        <a-button slot="button" type="primary" @click="handleBasicInfo">查看基本信息</a-button>
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span:4}"
            :wrapper-col="{ span: 16 }"
            :rules="rules"
            :model="form">
            <a-form-model-item
                v-for="(item,idx) in form.assessmentContentValueList"
                :key="item.id"
                :label="item.riskAssessmentContentName"
                :prop="`assessmentContentValueList[${idx}].riskOrderValue`"
                :rules="{
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                }">
                <a-input
                    v-model="item.riskOrderValue"
                    :max-length="INPUT_AREA_CONTENT_MAX_LENGTH"
                    :rows="8"
                    type="textarea" />
            </a-form-model-item>
            <div v-if="isLastStep">
                <a-form-model-item prop="underwritingOpinions" label="承保意见">
                    <a-select
                        v-model="form.underwritingOpinions"
                        style="width: 200px"
                        placeholder="请选择">
                        <a-select-option
                            v-for="item in INSURE_TYPE_ENUM"
                            :key="item.value"
                            :value="item.value">
                            {{ item.label }}
                        </a-select-option>
                        <!--                <a-select-option :value="2">谨慎承保</a-select-option>
                            <a-select-option :value="3">拒绝承保</a-select-option> -->
                    </a-select>
                </a-form-model-item>

                <a-form-model-item
                    label="风险提示"
                    prop="riskWarningList">
                    <a-input
                        v-model="form.riskWarningList"
                        :max-length="INPUT_AREA_CONTENT_MAX_LENGTH"
                        :rows="8"
                        type="textarea" />
                </a-form-model-item>
            </div>
        </a-form-model>
    </descriptions-with-head>
</template>

<script>
import {
    FormModel as AFormModel, Button as AButton,
    Input as AInput, Select as ASelect
} from 'ant-design-vue'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import { INSURE_TYPE_ENUM } from '@constant/enum'
import { INPUT_AREA_CONTENT_MAX_LENGTH } from '@constant/Const'

export default {
    name: 'FillAssessInfo',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput,
        DescriptionsWithHead,
        ASelect,
        AButton,
        ASelectOption: ASelect.Option
    },
    props: {
        index: {
            type: Number,
            default: 0
        },
        currentStep: {
            type: Number,
            default: 0
        },
        assessData: {
            type: Array,
            default() {
                return []
            }
        },
        assessObj: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            INPUT_AREA_CONTENT_MAX_LENGTH,
            INSURE_TYPE_ENUM,
            form: {},
            rules: {}
        }
    },
    computed: {
        stepLength() {
            const { assessData } = this
            return assessData.length
        },
        isLastStep() {
            const { stepLength, index } = this
            return index === stepLength - 1
        },
        // 当前索引位的对象数据
        currentObj() {
            const { index, assessData } = this
            return assessData[index]
        }
    },
    watch: {},
    created() {
        this.createForm()
        this.createRule()
    },
    methods: {
    /**
     * 查看基本信息
     * */
        handleBasicInfo() {
            this.$emit('basic')
        },
        /**
     * 动态生成form
     * */
        createForm() {
            const { isLastStep, assessObj, currentObj } = this
            const { riskWarningList, underwritingOpinions } = assessObj
            if (!isLastStep) {
                this.form = currentObj
            } else {
                this.form = {
                    ...currentObj,
                    riskWarningList: riskWarningList.join('\n'),
                    underwritingOpinions
                }
            }
        },
        /**
     * 动态生成rule
     * */
        createRule() {
            const { isLastStep } = this
            if (isLastStep) {
                this.rules = {
                    riskWarningList: [
                        {
                            required: true,
                            message: '请输入',
                            trigger: 'blur'
                        }
                    ],
                    underwritingOpinions: [
                        {
                            required: true,
                            message: '请选择',
                            trigger: 'blur'
                        }
                    ]
                }
            } else {
                this.rules = {}
            }
        },
        /**
     * 提交
     * */
        handleSubmit() {
            const { isLastStep, index } = this
            let res = {
                code: 0,
                index
            }
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if (isLastStep) {
                        const { riskWarningList, underwritingOpinions } = this.form
                        res = {
                            code: 1,
                            index,
                            underwritingOpinions,
                            riskWarningList: riskWarningList.split('\n').filter(v => v !== '')
                        }
                    } else {
                        res = {
                            code: 1,
                            index
                        }
                    }
                }
            })
            return res
        }
    }
}
</script>

<style lang='scss'>

</style>
