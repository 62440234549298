<template>
    <custom-modal
        class="risk-control-audit-modal"
        title="风控审核"
        :visible="visible"
        :width="600"
        :mask-closable="false"
        @close="handleCancel">
        <template slot="customFooter">
            <a-button
                v-if="showReject"
                typy="primary"
                :loading="rejectLoading"
                @click="handleSubmit(AUDIT_TYPE_NO_PASS,'rejectLoading')">
                驳回
            </a-button>
            <custom-button
                v-if="showRejectInsure"
                text="拒保"
                type="primary"
                :loading="noInsureLoading"
                @click="handleSubmit(AUDIT_TYPE_NO_ISSUE_REPORT,'noInsureLoading')"
            />
            <!-- 待高审没有提交 -->
            <a-button
                v-if="!isHighAudit"
                type="primary"
                :loading="submitLoading"
                @click="handleSubmit(AUDIT_TYPE_PASS,'submitLoading')">
                提交
            </a-button>
            <a-button
                v-if="showFinish"
                :loading="insureLoading"
                type="primary"
                @click="handleSubmit(AUDIT_TYPE_ISSUE_REPORT,'insureLoading')">
                报告签发
            </a-button>
        </template>
        <form-panel
            ref="formPanel"
            :risk-order-detail-d-t-o="riskOrderDetailDTO"
            :order-state-bool-fn-obj="orderStateBoolFnObj"
            @loading="handleLoading"
            @success="handleSuccess"
            @reload="handleReload" />
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import FormPanel from './FormPanel'
import CustomButton from '@components/Button'
import { orderStateBoolFnObj } from '@constant/function'

import {
    AUDIT_TYPE_PASS, AUDIT_TYPE_NO_PASS, AUDIT_TYPE_ISSUE_REPORT,
    AUDIT_TYPE_NO_ISSUE_REPORT, ORDER_SOURCE_GBW_TYPE
} from '@constant/enum'

export default {
    name: 'RiskControlAuditModal',
    components: {
        AButton,
        CustomModal,
        FormPanel,
        CustomButton
    },
    props: {
        riskOrderDetailDTO: {
            type: Object,
            default() {
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        },
        // 待复审是否显示签发按钮
        buttonPermission: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            submitLoading: false, // 提交loading
            rejectLoading: false, // 驳回loading
            insureLoading: false, // 签发
            noInsureLoading: false, // 拒保
            orderStateBoolFnObj,
            AUDIT_TYPE_PASS,
            AUDIT_TYPE_NO_PASS,
            AUDIT_TYPE_ISSUE_REPORT,
            AUDIT_TYPE_NO_ISSUE_REPORT
        }
    },
    computed: {
        // 展示驳回按钮 工保网&&数据核验岗没有驳回
        showReject() {
            return !(this.isAwaitEnter && this.riskOrderDetailDTO.orderSource === ORDER_SOURCE_GBW_TYPE)
        },
        // 待录入 数据核验无驳回
        isAwaitEnter() {
            return orderStateBoolFnObj.isAwaitEnter(this.riskOrderDetailDTO)
        },
        // 复审
        isAgainAudit() {
            return orderStateBoolFnObj.isAgainAudit(this.riskOrderDetailDTO)
        },
        // 高审
        isHighAudit() {
            return orderStateBoolFnObj.isHeightAudit(this.riskOrderDetailDTO)
        },
        // 是否显示拒保  //复审和高审
        showRejectInsure() {
            const { isAgainAudit, isHighAudit } = this
            return isHighAudit || isAgainAudit
        },
        // 是否有报告签发
        showFinish() {
            const { buttonPermission, isHighAudit } = this
            return isHighAudit || buttonPermission
        }
    },
    methods: {
        handleLoading(loadingName, val) {
            this[loadingName] = val
        },
        handleCancel() {
            this.$emit('cancel')
        },
        // 提交/驳回 // 拒保 /签发
        handleSubmit(...args) {
            this.$refs.formPanel.handleSubmit(...args)
        },
        handleSuccess() {
            this.$emit('success')
        },
        handleReload() {
            this.$emit('reload')
        }
    }
}
</script>

<style lang='scss'>
.risk-control-audit-modal {
  .ant-btn {
    width: 94px;
    height: 32px;
    text-align: center;
  }
}

</style>
