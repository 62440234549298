<!-- 指派任务列表 -->
<template>
    <list-page-layout>
        <div slot="header">
            <container-panel>
                <radio-group-panel
                    :radio-data="tabData"
                    :default-active-key="pendingType"
                    @change="handleRadioGroupChange"
                />
            </container-panel>
            <search-panel slot="header" @search="handleSearch" @refresh="handleRefresh" />
        </div>
        <container-panel :no-card-body-padding-top="true">
            <template slot="title">任务列表</template>
            <template slot="content">
                <a-table
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :loading="loading"
                    :scroll="scrollConfig"
                    row-key="id"
                    @change="handleTableChange">
                    <template slot="startTime" slot-scope="text,record">
                        {{ text }}至{{ record.endTime }}
                    </template>
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="TASK_DESIGNATE_BUTTON"
                            type="link"
                            @click="handleDesignate(action)">
                            指派
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
        <task-designate-modal
            :visible="visible"
            :info="info"
            @cancel="visible=false"
            @success="handleSuccess" />
    </list-page-layout>
</template>
<script>
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import RadioGroupPanel from '@components/RadioGroupPanel'
import SearchPanel from './SearchPanel'
import TaskDesignateModal from './TaskDesignateModal'

import columns from './columns'

import { BUSINESS_TYPE_BEFORE_VALUE } from '@constant/enum'
import {
    TASK_DESIGNATE_BUTTON
} from '@constant/authEnum/task'
import { DEFAULT_PAGES_SIZE } from '@config'

import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'

import getAssignTasksListService from '@service/task/getAssignTasksListService'

export default {
    name: 'TaskDesignate',
    components: {
        TaskDesignateModal,
        SearchPanel,
        ListPageLayout,
        ContainerPanel,
        RadioGroupPanel,
        AButton,
        ATable
    },
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            TASK_DESIGNATE_BUTTON,
            columns,
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            info: {}, // 需要转发的任务信息
            pendingType: BUSINESS_TYPE_BEFORE_VALUE + '',
            params: {},
            scrollConfig: {
                x: 1100,
                y: 500
            },
            tabData: [
                {
                    key: BUSINESS_TYPE_BEFORE_VALUE + '',
                    label: '保前任务'
                }
                // {
                //   key: BUSINESS_TYPE_AFTER_VALUE+'',
                //   label: '保后任务'
                // }
            ]
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleSuccess(isShow) {
            this.getList()
            this.visible = isShow
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        /**
         * 任务指派
         * */
        handleDesignate(action) {
            this.info = action
            this.visible = true
        },
        handleRadioGroupChange(e) {
            this.pendingType = e.target.value
            this.current = 1
            this.getList()
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    currentPage: current,
                    pageSize: limit,
                    ...params
                }
                const result = await getAssignTasksListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss">
</style>
