<template>
    <custom-modal
        title="企业历史评分"
        :footer="false"
        :visible="visible"
        :loading="loading"
        :mask-closable="false"
        :width="650"
        @close="handleCancel">
        <a-table
            :columns="columns"
            :data-source="dataList"
            :pagination="false"
            :loading="loading"
            :scroll="scrollConfig"
            row-key="riskControlNumber"
        >
            <template slot="action" slot-scope="action">
                <a-button
                    v-auth="LOOK_ORDER"
                    type="link"
                    @click="handleDetail(action)"
                >
                    查看
                </a-button>
            </template>
        </a-table>
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import initTable from '@mixins/initTable'
import { RISK_HISTORY_SCORE_LOOK_ORDER_BUTTON as LOOK_ORDER } from '@constant/authEnum/task'
import { open } from '@utils'
import getListService from '@service/task/getHistoryScoreService'

const columns = [
    {
        title: '序号',
        dataIndex: 'sort',
        align: 'left',
        key: 'sort',
        fixed: 'left',
        width: 100,
        customRender: (v, r, idx) => ++idx
    },
    {
        title: '项目名称',
        dataIndex: 'projectName',
        align: 'left',
        key: 'projectName',
        width: 250
    },
    {
        title: '中标金额',
        dataIndex: 'bidWinAmo',
        align: 'left',
        key: 'bidWinAmo',
        width: 200
    },
    {
        title: '风险评分等级',
        dataIndex: 'scoreAndRiskLevel',
        align: 'left',
        key: 'scoreAndRiskLevel',
        width: 120
    },
    {
        title: '操作人 ',
        dataIndex: 'createName',
        align: 'left',
        key: 'createName',
        width: 200
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        },
        width: 150,
        fixed: 'right'
    }
]
export default {
    name: 'AddOrEditCategoryModal',
    components: {
        AButton,
        CustomModal
    },
    mixins: [initTable],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            LOOK_ORDER,
            columns,
            loading: false
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        handleDetail({ riskControlNumber }) {
            open.call(this, 'OrderListInfo', {
                riskControlNumber
            })
        },
        async getList() {
            try {
                this.loading = true
                const { riskControlNumber } = this
                const param = {
                    riskControlNumber
                }
                this.dataList = await getListService(param)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
