<!-- 修改风险评分 -->
<template>
    <page-layout
        :show-loading="true"
        :loading="loading"
        class="edit-risk-grade">
        <descriptions-with-head
            :title="`单号：${riskControlNumber}`"
            class="edit-risk-grade__description">
            <a-form-model
                ref="ruleForm"
                layout="horizontal"
                :label-col="{ span:4}"
                :wrapper-col="{ span: 16 }"
                :model="form">
                <div class="edit-risk-grade__head">
                    <span>风险评分评级:</span>
                    <span class="edit-risk-grade__head__right">
                        <span
                            class="edit-risk-grade__head__right__num">{{ form.score }}</span>{{ form.riskLevelDesc }}
                    </span>
                    <a-button v-auth="LOOK_MORE" type="link" @click="historyVisible=true">查看更多</a-button>
                </div>

                <table style="width: 100%">
                    <thead class="edit-risk-grade__thead">
                        <tr>
                            <th>风险对象</th>
                            <th>评分系数</th>
                            <th>选择属性</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="delay(2)">
                            <tr
                                v-for="(item,index) in projectFactorValueList"
                                :key="item.id">
                                <td>
                                    <a-form-model-item>
                                        <span v-show="index===0">
                                            项目
                                        </span>
                                    </a-form-model-item>
                                </td>
                                <td>
                                    <a-form-model-item>
                                        {{ item.factorName }}
                                    </a-form-model-item>
                                </td>
                                <td>
                                    <a-form-model-item
                                        :prop="`projectFactorValueList[${index}].riskControlAdjustmentValue`"
                                        :rules="{
                                            required: true,
                                            message: '请输入',
                                            trigger: 'blur',}">
                                        <dict-select
                                            v-if="isDict(item.dataType)"
                                            v-model="item.riskControlAdjustmentValue"
                                            :params="item" />
                                        <a-input
                                            v-else
                                            v-model="item.riskControlAdjustmentValue"
                                            type="text" />
                                    </a-form-model-item>
                                </td>
                            </tr>
                        </template>
                        <template v-if="delay(3)">
                            <tr
                                v-for="(item,index) in enterpriseFactorValueList"
                                :key="item.id">
                                <td>
                                    <a-form-model-item>
                                        <span v-show="index===0">企业</span>
                                    </a-form-model-item>
                                </td>
                                <td>
                                    <a-form-model-item>
                                        {{ item.factorName }}
                                    </a-form-model-item>
                                </td>
                                <td>
                                    <a-form-model-item
                                        :prop="`enterpriseFactorValueList[${index}].riskControlAdjustmentValue`"
                                        :rules="{
                                            required: true,
                                            message: '请输入',
                                            trigger: 'blur',}">
                                        <dict-select
                                            v-if="isDict(item.dataType)"
                                            v-model="item.riskControlAdjustmentValue"
                                            :params="item" />
                                        <a-input
                                            v-else
                                            v-model="item.riskControlAdjustmentValue"
                                            type="text" />
                                    </a-form-model-item>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>

                <a-form-model-item
                    :wrapper-col="{ span: 12, offset: 6 }"
                    style="margin-top: 30px; text-align: center">
                    <a-space>
                        <custom-button
                            text="取消"
                            @click="handleCancel" />
                        <custom-button
                            text="提交"
                            type="primary"
                            :loading="submitLoading"
                            @click="handleSubmit" />
                    </a-space>
                </a-form-model-item>
            </a-form-model>
        </descriptions-with-head>
        <submit-tips-modal
            v-if="delay(4)"
            :visible="visible"
            :info="form"
            @cancel="visible=false"
            @success="handleSuccess"
        />
        <history-score-modal
            v-if="delay(5)"
            :visible="historyVisible"
            @cancel="historyVisible=false" />
    </page-layout>
</template>

<script>
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import PageLayout from '@layout/PageLayout'
import { FormModel as AFormModel, Space as ASpace, Input as AInput, Button as AButton } from 'ant-design-vue'
import CustomButton from '@components/Button'
import getRiskScoreValueService from '@service/task/getRiskScoreValueService'
import { RISK_FACTOR_DATA_TYPE_DICT_TYPE } from '@constant/enum'
import { RISK_CONTROL_INFO_LOOK_HISTORY_SCORE_BUTTON as LOOK_MORE } from '@constant/authEnum/task'
import delay from '@mixins/delay'

export default {
    name: 'EditRiskGrade',
    components: {
        AButton,
        DescriptionsWithHead,
        PageLayout,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        CustomButton,
        ASpace,
        AInput,
        SubmitTipsModal: () => import('./SubmitTipsModal'),
        HistoryScoreModal: () => import('./HistoryScoreModal'),
        DictSelect: () => import('@weights/GbCloud/DictSelect')
    },
    mixins: [delay(5)],
    data() {
        return {
            LOOK_MORE,
            historyVisible: false,
            loading: false,
            submitLoading: false,
            form: {},
            visible: false,
            enterpriseFactorValueList: [],
            projectFactorValueList: []
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
        isDict(dataType) {
            return dataType === RISK_FACTOR_DATA_TYPE_DICT_TYPE
        },
        handleCancel() {
            this.$router.back()
        },
        handleSuccess() {
            this.visible = false
            this.getInfo()
        },
        handleSubmit() {
            const { riskControlNumber } = this
            this.form.riskControlNumber = riskControlNumber
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.visible = true
                }
            })

        },
        async getInfo() {
            try {
                this.loading = true
                const { riskControlNumber } = this
                this.form = await getRiskScoreValueService({
                    riskControlNumber
                })
                const { enterpriseFactorValueList, projectFactorValueList } = this.form
                this.enterpriseFactorValueList = enterpriseFactorValueList
                this.projectFactorValueList = projectFactorValueList
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }

    }
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';

.edit-risk-grade {
    &__head {
        width: 100%;
        padding: 0 26px;
        height: 44px;
        line-height: 44px;
        background: #fff;
        border: 1px solid #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__right {
            //margin-left: 560px;
            color: #f00;

            &__num {
                margin-right: 26px;
            }
        }
    }

    &__description {
        background-color: #fff;
    }

    table {
        margin-top: 22px;
    }

    &__thead {
        background-color: $custom-table-title-background-color;
        line-height: 44px;
    }

    tr {
        line-height: $custom-table-thead-height;

        th,
        td {
            padding-left: 26px;
            width: 33%
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid #e8e8e8;
        }
    }
}
</style>
