<template>
    <page-layout class="task-info-page" :show-loading="true" :loading="loading">
        <!-- 头部信息 -->
        <task-head
            :button-permission="taskInfo.buttonPermission"
            :button-permission-for-finance="taskInfo.buttonPermissionForFinance"
            :risk-order-detail-d-t-o="riskOrderDetailDTO"
            @reload="handleReload">
            <tabs-panel
                class="task-info-page__tabs"
                :tabs-data="tabsData"
                :default-active-key="defaultActiveKey"
                @handle-tabs-change="handleTabsChange"
            />
        </task-head>

        <component
            :is="defaultActiveKey"
            :task-info="taskInfo"
            @risk="handleRisk" />
    </page-layout>
</template>

<script>
import {
    Descriptions as ADescriptions,
    Button as AButton
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import PagePanel from '@components/PagePanel'
import TabsPanel from '@components/TabsPanel'
import ContainerPanel from '@components/ContainerPanel'
import getTaskInfoService from '@service/task/getTaskInfoService'
import enumOperate from '@mixins/enumOperate'
import {
    ORDER_BUSINESS_STATE_INIT_STATE_TYPE,
    ORDER_STATE_TYPE_ENUM_LIST, ORDER_BUSINESS_STATE_ENUM_LIST,
    LOOK_THREE_TABLE_LIST, LOOK_FOUR_TABLE_LIST, LOOK_FIVE_TABLE_LIST
} from '@constant/enum'
import { tabsThreeData, tabsFourData, tabsFiveData } from '@views/Order/OrderInfo/tabsDataList'

export default {
    name: 'TaskInfo',
    components: {
        PageLayout,
        PagePanel,
        TabsPanel,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        ContainerPanel,
        AButton,
        OrderDetailInfoPanel: () => import('@weights/TaskAndOrderTabPanel/OrderDetailInfoPanel'),
        RiskControlInfoPanel: () => import('@weights/TaskAndOrderTabPanel/RiskControlInfoPanel'),
        RiskAccessInfoPanel: () => import('@weights/TaskAndOrderTabPanel/RiskAccessInfoPanel'),
        RelevanceInfoPanel: () => import('@weights/TaskAndOrderTabPanel/RelevanceInfoPanel'),
        OperationLogInfoPanel: () => import('@weights/TaskAndOrderTabPanel/OperationLogInfoPanel'),
        InsureInfoPanel: () => import('@weights/TaskAndOrderTabPanel/InsureInfoPanel'),
        TaskHead: () => import('./TaskHead')
    },
    mixins: [enumOperate],
    data() {
        return {
            ORDER_BUSINESS_STATE_INIT_STATE_TYPE,
            ORDER_STATE_TYPE_ENUM_LIST,
            riskOrderDetailDTO: {},
            defaultActiveKey: 'OrderDetailInfoPanel',
            loading: false,
            taskInfo: {}
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        state() {
            const { riskOrderDetailDTO } = this
            const { businessState, stateValue } = riskOrderDetailDTO
            if (businessState === ORDER_BUSINESS_STATE_INIT_STATE_TYPE) {
                return stateValue
            } else {
                return this.getApiEnumLabelFromList(ORDER_BUSINESS_STATE_ENUM_LIST, businessState)
            }
        },
        /**
     * 动态tab
     * */
        tabsData() {
            const { riskOrderDetailDTO } = this
            const { businessState, state } = riskOrderDetailDTO
            let tabsData = []
            if (businessState === ORDER_BUSINESS_STATE_INIT_STATE_TYPE) { // 业务状态为1
                if (LOOK_THREE_TABLE_LIST.find(item => item === state)) {
                    tabsData = tabsThreeData
                } else if (LOOK_FOUR_TABLE_LIST.find(item => item === state)) {
                    tabsData = tabsFourData
                } else if (LOOK_FIVE_TABLE_LIST.find(item => item === state)) {
                    tabsData = tabsFiveData
                }
            } else { // 不为1
                tabsData = tabsFiveData
            }
            // 承保信息是否显示
            if (!this.taskInfo.haveInsuranceInfo) {
                tabsData = tabsData.filter(item => item.key !== 'InsureInfoPanel')
            }
            return tabsData
        }
    },
    watch: {
        $route: {
            handler() {
                this.getInfo()
            },
            immediate: true
        }
    },
    methods: {
    /**
     * 重新请求接口
     * */
        handleReload() {
            this.getInfo()
        },
        /**
     * 查看关联单
     * */
        handleRisk(val) {
            const query = this.$route.query
            this.$router.push({
                name: 'TaskInfo',
                query: {
                    ...query,
                    riskControlNumber: val
                }
            })
        },
        /**
     * tab改变
     */
        handleTabsChange(key) {
            this.defaultActiveKey = key
        },
        /**
     * 获取任务详情
     * */
        async getInfo() {
            try {
                this.loading = true
                const { riskControlNumber } = this
                this.taskInfo = await getTaskInfoService({
                    riskControlNumber
                })
                const { riskOrderDetailDTO, associatedOrderList } = this.taskInfo
                this.riskOrderDetailDTO = riskOrderDetailDTO || {}
                this.associatedOrderList = associatedOrderList || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.task-info-page {
  &__info {
    margin-top: -24px;
    display: flex;
  }

  &__title {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: bold;
    color: #0f0f0f;
  }

  &__info-panel {
    flex: 1;
  }

  &__info-status {
    width: 250px;
    text-align: right;
    padding-right: 5px;
  }

  .task-info-page-panel {
    .ant-card-body {
      padding: $ant-card-body-padding $ant-card-body-padding 0;
    }

    .ant-tabs {
      border-top: 1px dashed $border-color;
    }
  }
}
</style>
