<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="风控单号：" prop="number">
            {{ info.riskControlNumber }}
        </a-form-model-item>
        <a-form-model-item label="原负责人：" prop="origin">
            {{ info.assigneeName }}
        </a-form-model-item>
        <a-form-model-item label="将任务转发给：" prop="agent">
            <principal-select
                v-model="form.agent"
                :assignee-name="info.assigneeName"
                :risk-control-number="info.riskControlNumber"
                @change="handleChange" />
        </a-form-model-item>
        <a-form-model-item label="备注：" prop="comment">
            <a-input
                v-model="form.comment"
                :max-length="INPUT_AREA_MAX_LENGTH"
                placeholder="请输入"
                type="textarea" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel, Input as AInput
} from 'ant-design-vue'
import PrincipalSelect from '@weights/PrincipalSelect'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'
import { INPUT_AREA_MAX_LENGTH } from '@constant/Const'

import postForwardTaskService from '@service/task/postForwardTaskService'

export default {
    name: 'FormPanel',
    components: {
        AInput,
        PrincipalSelect,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            INPUT_AREA_MAX_LENGTH,
            form: {
                comment: '',
                agentName: '',
                agent: ''
            },
            rules: {
                agent: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择'
                    }
                ],
                comment: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入'
                    }
                ]
            }
        }
    },
    computed: {},
    watch: {},
    methods: {
        handleChange(name) {
            this.form.agentName = name
        },
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                }
            })
        },
        async submitService() {
            const { form, info } = this
            const { riskControlNumber } = info
            form.riskControlNumber = riskControlNumber
            try {
                await postForwardTaskService(form)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
